const scales = {
  "0-5" : {
    min: 0,
    max: 5,
    step: 1,
    tag: (i) => {
      if(i === -1) return "Ungraded";
      if(i === 0) return "Never";
      if(i === 1) return "Rarely";
      if(i === 2) return "Sometimes";
      if(i === 3) return "Mostly";
      if(i === 4) return "Consistently";
      if(i === 5) return "Exceptional";
    }
  },
  "0-5i": {
    min: 0,
    max: 5,
    step: 1,
    tag: (i) => i === -1 ? "Ungraded": i
  },
  "0-10": {
    min: 0,
    max: 10,
    step: 1,
    tag: (i) => i === -1 ? "Ungraded": i
  },
  "0-15": {
    min: 0,
    max: 15,
    step: 1,
    tag: (i) => i === -1 ? "Ungraded": i
  },
  "0-20": {
    min: 0,
    max: 20,
    step: 1,
    tag: (i) => i === -1 ? "Ungraded": i
  },
  "0-12": {
    min: 0,
    max: 12,
    step: 1,
    tag: (i) => {
      if(i === -1) return "Ungraded";
      if(i === 0) return "Never";
      if(i === 1 || i === 2) return "Rarely";
      if(i === 3 || i === 4) return "Sometimes";
      if(i === 5 || i === 6 || i === 7) return "Mostly";
      if(i === 8 || i === 9 || i === 10) return "Consistently";
      if(i === 11 || i === 12) return "Exceptional";
    }
  },
  "0-5.0": {
    min: 0,
    max: 5.0,
    step: .25,
    tag: (i) => {
      if(i === -1) return "Ungraded";
      if(i === 0) return "Never";
      if(i > 0 && i <= 1) return "Rarely";
      if(i > 1 && i <= 2) return "Sometimes";
      if(i > 2 && i <= 3) return "Mostly";
      if(i > 3 && i <= 4) return "Consistently";
      if(i >= 4 && i <= 5) return "Exceptional";
    }
  }
};

export default scales;