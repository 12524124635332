import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Redirect } from "react-router-dom";

import ls from 'local-storage';
import jwt from 'jwt-decode';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Deadline extends React.Component {

  componentDidMount() {
    if(ls('grader-jwt')) {
        fetch('https://api.coloradoaso.org/grader-refresh-token', {
        method: 'get',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'grader': ls('grader-jwt')
        },
      })
      .then(res => res.json())
      .then(res => {
        if(res.error) {
          return console.log('refresh error', res);
        }
        ls('grader-jwt', res.token);
        console.log(jwt(res.token));
      });
    }
    
  }

  render() {


    const { classes, user } = this.props;

    if(user && new Date() < new Date(user.deadline)) {
      return  <Redirect to={{ pathname: '/grader', state: { from: this.props.location }}} />;
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Past Deadline!
            </Typography>
            <Typography style={{textAlign: 'center'}}>
              Contact <a href="mailto:coloradoaso@gmail.com">coloradoaso@gmail.com</a> to extend the deadline.
            </Typography>
            <div  style={{textAlign: 'center'}}>{user.name}</div>
          </div>
      </Container>
    );
  }
}

export default withStyles(styles)(Deadline);