import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@material-ui/core/Snackbar';
import {
  DataTypeProvider,
  EditingState,
  SortingState,
  SearchState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
  SearchPanel,
  Toolbar,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import ls from 'local-storage';

const styles = theme => ({
});

const getRowId = row => row._id;

const PaymentFormatter = ({ value }) => (
  <span>
    {value.payment1 ? value.payment1 : "Not Submitted"} {value.payment2}
  </span>
);

const PaymentEditor = ({ value, onValueChange, onBlur }) => (
  <div>
    <Select
      input={<Input />}
      value={value.payment1}
      onChange={event => onValueChange({...value, payment1: event.target.value})}
      style={{ width: '100%', marginBottom: 5 }}
    >
      <MenuItem value="Online">
        Online
      </MenuItem>
      <MenuItem value="School">
        School
      </MenuItem>
      <MenuItem value="Check">
        Check
      </MenuItem>
      <MenuItem value="">
        Not Submitted
      </MenuItem>
    </Select>
    {value.payment1 && (value.payment1 === "Check" || value.payment1 === "Online") && (
      <TextField
        value={value.payment2}
        onChange={event => onValueChange({...value, payment2: event.target.value})}
        label="Number"
        fullWidth
      />
    )}
    <Button variant="contained" color="primary" style={{marginTop: 5}} onClick={() => onBlur()}>Save</Button>
  </div>
)

const PaymentTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={PaymentFormatter}
    editorComponent={PaymentEditor}
    {...props}
  />
);

const BooleanFormatter = ({ value }) => <Chip label={value ? 'Yes' : 'No'} />;

const BooleanEditor = ({ value, onValueChange, onBlur }) => (
  <div style={{position: 'relative'}}>
    <Select
      input={<Input />}
      value={value ? 'Yes' : 'No'}
      onChange={event => {
        onValueChange(event.target.value === 'Yes');
        setTimeout(() => onBlur(), 0);
      }}
      style={{ width: 'calc(100% - 30px)' }}
    >
      <MenuItem value="Yes">
        Yes
      </MenuItem>
      <MenuItem value="No">
        No
      </MenuItem>
    </Select>
    <IconButton color="secondary" style={{position: 'absolute', top: -10, right: -10}} onClick={() => onBlur()} component="span">
      <CancelIcon/>
    </IconButton>
  </div>
  
);

const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
);

class StudentList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      columns: [
        { name: 'firstName', title: 'First Name' },
        { name: 'lastName', title: 'Last Name' },
        { name: 'email', title: 'Email' },
        { name: 'director', title: 'Director', getCellValue: row => `${row.director.firstName} ${row.director.lastName}`},
        { name: 'school', title: 'School', getCellValue: row => row.director.school},
        { name: 'payment', title: 'Payment'},
        { name: 'payment_validated', title: 'Payment Valid'},
      ],
      sorting: [{ columnName: 'name', direction: 'asc' }],
      booleanColumns: ['payment_validated'],
      rows: [],
    };
    this.changeSorting = sorting => this.setState({ sorting });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (changed) {
        const prevRow = rows.find(r => changed[r._id]);
        if (prevRow) {
          const newRow = {
            ...prevRow,
            ...changed[prevRow._id]
          };
          fetch('https://admin-api.coloradoaso.org/student', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'authorization': ls('admin-jwt')
            },
            body: JSON.stringify({
              updates: [newRow]
            })
          })
            .then(res => res.json())
            .then(res => {
              if (!res.error) {
                this.setState({
                  rows: res.rows
                })
              } else {
                this.setState({
                  error: `Something seems to have gone wrong(${res.error}).`
                });
              }
            });
        }
      }
      if (added) {
        fetch('https://admin-api.coloradoaso.org/student', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            news: added
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.rows
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
      if (deleted) {
        fetch('https://admin-api.coloradoaso.org/student', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            dels: deleted
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.rows
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
    };
  }

  componentDidMount() {
    fetch("https://admin-api.coloradoaso.org/students", {
      method: 'get',
      headers: {
        'authorization': ls('admin-jwt')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            rows: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  render() {
    const { rows, columns, sorting, booleanColumns, error } = this.state;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.error ? true : false}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{error} Let Patrick know before trying to modify any data.</span>}
        />

        <Typography align="center" variant="h4" component="h4">
          Payments
        </Typography>
        <div style={{maxWidth: '95vw'}}>

        
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <BooleanTypeProvider
            for={booleanColumns}
          />
          <PaymentTypeProvider for={["payment"]} />
          <EditingState
            onCommitChanges={this.commitChanges}
            columnExtensions={[
              {columnName: 'name', editingEnabled: false},
              {columnName: 'director', editingEnabled: false},
              {columnName: 'school', editingEnabled: false}
            ]}
          />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={25}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <SearchState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          
          <Table 
            columnExtensions={[
              {columnName: 'payment_validated', wordWrapEnabled: true, width: 100},
              {columnName: 'firstName', wordWrapEnabled: false, width: 150},
              {columnName: 'lastName', wordWrapEnabled: false, width: 150},
            ]}
          />
          
          <TableHeaderRow showSortingControls/>
          <Toolbar />
          <SearchPanel />
          <TableInlineCellEditing
            startEditAction={'click'}
            selectTextOnEditStart={true}
          />
          <PagingPanel 
            pageSizes={[5, 10, 25, 50, 100, 0]}
          />
          
          
        </Grid>
        </div>
      </React.Fragment>
    );
  }
}

StudentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentList);