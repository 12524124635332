import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import GraderLayout from './components/GraderLayout';
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  footer: {
    marginTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(6)}px 0`,
  },
});

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      auditions: {},
      counts: null
    }

  }


  setAuditions(auditions) {
    const {user} = this.props;
    const counts = {};
    for(const instrument of user.instruments) {
      counts[instrument] = {};
      let code = null;
      counts[instrument].total = auditions[instrument].length;
      for(const audition of auditions[instrument]) {
        let gradeFound = false;
        for(const grader of Object.keys(audition.grades)) {
          if(grader === user.name) gradeFound = true;
          if(!counts[instrument][grader]) counts[instrument][grader] = 0;
          counts[instrument][grader] = counts[instrument][grader] + 1;
        }
        if(!gradeFound && !code) code = audition.code;
      }
      counts[instrument].next = code;
    }
    this.setState({
      auditions: auditions,
      counts
    });
  }

  render() {

    if(this.state.redirect) return <Redirect push to={this.state.redirect} />


    const {user, classes} = this.props;

    return (
      <GraderLayout setAllAuditions={(auditions) => this.setAuditions(auditions)} user={user}> 
        <div className={classes.heroContent}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Hi, {user.name}
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" component="p">
            Welcome to Colorado All State Orchestra adjudications!
          </Typography>
        </div>
        {this.state.counts && <Grid container spacing={5} justify="center" alignItems="center">
          {user.instruments.map((instrument) => (
            <Grid key={instrument} item xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title={instrument}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {this.state.counts[instrument][user.name]||0}/{this.state.counts[instrument].total}
                    </Typography>
                  </div>
                  <Typography variant="subtitle1" align="center">
                    Auditions
                  </Typography>
                </CardContent>
                {this.state.counts[instrument].next && <CardActions className={classes.cardActions}>
                  <Button component={Link} to={`/grader/audition/${this.state.counts[instrument].next}`} fullWidth variant="outlined" color="primary">
                    Next {instrument} Audition
                  </Button>
                </CardActions>}
              </Card>
            </Grid>
          ))}
          
        </Grid>}
      </GraderLayout>
        
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);