import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import styles from './wideformTheme';

class WideForm extends React.Component {

  render() {
    const { classes, steps, CurrentStep, step_index, title } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Colorado All State Orchestra
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {title}
            </Typography>
            <Hidden smDown>
              <Stepper activeStep={step_index} className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Hidden>
            <React.Fragment>
              <CurrentStep {...this.props} />
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

WideForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WideForm);