import React from 'react';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import styles from '../../theme';


function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

class Review extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: ''
    }
    this.submitInfo = this.submitInfo.bind(this);
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  submitInfo() {
    this.postData("https://api.coloradoaso.org/student-confirm", {
      _id: this.props.match.params.id,
    }).then(res => {
      this.setState({
        redirect: this.props.match.params.id
      });
    }).catch(err => {
      console.log(err);
    });
  }

  componentDidMount() {
    this.postData("https://api.coloradoaso.org/student-id", {_id: this.props.match.params.id}).then(res => {
      this.setState(Object.assign({
        loading: false
      }, res));
    }).catch(err => {
      console.log(err);
    });
  }

  postData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(response => response.json()); // parses response to JSON
  }

  render() {

    if (this.state.redirect.length > 0) {
      return <Redirect to={`/student-registration/success/${this.state.redirect}`} />
    }

    const { classes } = this.props;
    const { 
      firstName,
      lastName,
      address,
      email,
      cell,
      home,
      emergency,
      director
    } = this.state;
    if(this.state.loading) return <div>Loading...</div>;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Review Registration
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <b>{director.school} - {director.name}</b>
            </Typography>
          </Grid>
          <Grid item>
            <b>{firstName} {lastName}</b><br />
            {address.address1}<br />
            {address.address2.length > 0 ? (<span>{address.address2}<br /></span>) : null}
            {address.city}, {address.state} {address.zip}<br />
            {email}<br />
            Cell: {formatPhoneNumber(cell)}<br />
            {home.length > 0 ? (<span>{formatPhoneNumber(home)}<br /></span>) : null}
          </Grid>
          <Grid item>
            <b>Emergency Contact</b><br />
            {emergency.name}<br />
            {formatPhoneNumber(emergency.number)}<br />
            {emergency.relationship}
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.submitInfo}
          >
            Finish
          </Button>
        </div>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(Review);
