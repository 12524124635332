import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import GraderLayout from './components/GraderLayout';
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  SearchPanel,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Redirect } from "react-router-dom";

const Link1 = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const CodeFormatter = ({value}) => (
  <Link to={`/grader/audition/${value}`} component={Link1}>{value}</Link>
);

const CodeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={CodeFormatter}
    {...props}
  />
);

const compareScores = (a, b) => {
  if(a === "Not Graded" && b === "Not Graded") return 0;
  if(a === "Not Graded") return 1;
  if(b === "Not Graded") return -1;
  return Number(a) > Number(b) ? 1 : -1;
}

const getRowId = row => row.code;

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  footer: {
    marginTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(6)}px 0`,
  },
});

class Review extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      auditions: {},
      columns: [
        { name: "code", title: "Student Code" }
      ],
      sorting: [{ columnName: 'code', direction: 'asc' }],
    }

    for(const instrument of props.user.instruments) {
      this.state[instrument] = [];
    }

    this.changeSorting = sorting => this.setState({ sorting });

  }



  setAuditions(auditions) {
    const {user} = this.props;
    const state = {};
    const seenGraders = [];
    for(const instrument of user.instruments) {
      state[instrument] = [];
      for(const audition of auditions[instrument]) {
        const gradeObj = {};
        let average = 0;
        let averageCount = 0;
        for(const grader of Object.keys(audition.grades)) {
          average += audition.grades[grader].Total;
          averageCount ++;
          if(seenGraders.indexOf(grader) === -1) seenGraders.push(grader);
          gradeObj[grader] = {
            total: audition.grades[grader].Total
          }
        }
        gradeObj.average = {};
        gradeObj.average.total = averageCount === 0 ? "Not Graded" : Math.round(100*average/averageCount)/100;
        state[instrument].push({
          ...gradeObj,
          code: audition.code,
        });
      }
    }
    const columns = [
      { name: "code", title: "Student Code" }
    ];
    for(const grader of seenGraders) {
      columns.push({
        name: `${grader} Total`,
        title: `${grader} Total`,
        getCellValue: row => row[grader] ? row[grader].total : "Not Graded"
      });
    }
    columns.push({
      name: "Average Total",
      title: "Average Total",
      getCellValue: row => row.average.total
    });
    this.setState({
      ...state,
      columns,
      auditions,
    });
  }

  render() {

    if(this.state.redirect) return <Redirect push to={this.state.redirect} />

    const {user} = this.props;

    const {columns, sorting} = this.state;

    return (
      <GraderLayout setAllAuditions={(auditions) => this.setAuditions(auditions)} user={user}> 
        {user.instruments.map(instrument => (<Paper style={{marginBottom: 20}} key={instrument}>
          <Typography align="center" variant="h4" component="h4">
            {instrument}
          </Typography>
          <Grid
            rows={this.state[instrument]}
            columns={columns}
            getRowId={getRowId}
          >
            <CodeTypeProvider for={["code"]} />
            <PagingState
              defaultCurrentPage={0}
              defaultPageSize={25}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <SearchState />

            <IntegratedFiltering />
            <IntegratedSorting 
              columnExtensions={[
                { columnName: 'Average Total', compare: compareScores }
              ]}
            />
            <IntegratedPaging />
            
            <Table />
            
            <TableHeaderRow showSortingControls/>
            <Toolbar />
            <SearchPanel />
            <PagingPanel 
              pageSizes={[5, 10, 25, 50, 100, 0]}
            />
          </Grid>
        </Paper>))}
        
      </GraderLayout>
        
    );
  }
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Review);