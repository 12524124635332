import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import School from '@material-ui/icons/School';
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait';
import LibraryMusic from '@material-ui/icons/LibraryMusic';

import styles from '../../theme';

class Review extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  componentDidMount() {
    this.postData("https://api.coloradoaso.org/director-id", {_id: this.props.match.params.id}).then(res => {
      this.setState(Object.assign({
        loading: false
      }, res));
    }).catch(err => {
      console.log(err);
    });
  }

  postData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(response => response.json()); // parses response to JSON
  }


  render() {
    const { classes } = this.props;
    if(this.state.loading) return <div>Loading...</div>;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Review Registration
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={8}>
              <Grid item>
                <Face />
              </Grid>
              <Grid item xs>
                <Typography>{this.state.firstName} {this.state.lastName}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={8}>
              <Grid item>
                <Email />
              </Grid>
              <Grid item xs>
                <Typography>{this.state.email}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={8}>
              <Grid item>
                <School />
              </Grid>
              <Grid item xs>
                <Typography>{this.state.school}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={8}>
              <Grid item>
                <StayCurrentPortrait />
              </Grid>
              <Grid item xs>
                <Typography>{this.formatPhoneNumber(this.state.cell)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={8}>
              <Grid item>
                <Phone />
              </Grid>
              <Grid item xs>
                <Typography>{this.formatPhoneNumber(this.state.work)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={8}>
              <Grid item>
                <LibraryMusic />
              </Grid>
              <Grid item xs>
                <Typography>{this.state.nafme}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            component={Link}
            to={`/director-registration/success/${this.props.match.params.id}`}
            variant="contained"
            color="primary"
            onClick={this.submitInfo}
            className={classes.button}
            disabled={this.state.loading}
          >
            {this.state.loading ? "..." : "Finish"}
          </Button>
        </div>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(Review);
