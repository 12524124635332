import React from 'react';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RateReview from '@material-ui/icons/RateReview';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';

import { Redirect } from "react-router-dom";

import NestedList from './NestedList';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});


function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}



class GraderLayout extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      loading: true,
      auditions: {}
    }
    this.setMobileOpen = this.setMobileOpen.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.logout = this.logout.bind(this);
  }

  setMobileOpen(val) {
    this.setState({
      mobileOpen: val
    });
  }

  handleDrawerToggle() {
    this.setMobileOpen(!this.state.mobileOpen);
  }
  

  loadAuditions() {
    fetch("https://api.coloradoaso.org/grader-auditions", {
      method: 'get',
      headers: {
        'grader': ls('grader-jwt')
      }
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          auditions: result
        });
        this.props.setAllAuditions(result);
      },
      (error) => {
        this.setState({
          error: error.message
        });
      }
    )
  }

  componentDidMount() {
    this.loadAuditions();
  }

  logout() {
    ls.remove('grader-jwt');
    this.setState({
      redirect: '/grader/login'
    });
  }

  render() {

    if(this.state.redirect) return <Redirect to={this.state.redirect} />

    const { user, classes, container, children } = this.props;
    const { auditions } = this.state;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItemLink to="/grader" primary="Dashboard" icon={<InboxIcon />} />  
          <ListItemLink to="/grader/review" primary="Review" icon={<RateReview />} />
        </List>
        {user.instruments.map((instrument) => (
          <React.Fragment key={instrument}>
            <Divider />
            <NestedList 
              title={`${instrument} Auditions`}
              items={auditions[instrument]||[]}
            />
          </React.Fragment>
        ))}
        <Divider />
      </div>
    );

    

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography style={{flex:1}} variant="h6" noWrap>
              Adjudication
            </Typography>
            <Button onClick={this.logout} color="primary" variant="contained">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor="left"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }

}

GraderLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GraderLayout);