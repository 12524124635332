import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import AdminHeader from './adminheader';
import SchoolListComponent from './schoolListComponent';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    width: 'auto'
  },
});

class AdminSchools extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AdminHeader />
        <Grid container justify="center">
          <Grid item xs={12} md={4}>
            <Paper className={classes.root}>
              <SchoolListComponent/>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

}

AdminSchools.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminSchools);