const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(4))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(6))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  verifyButton: {
    marginTop: theme.spacing(2)
  },
  verifyField: {
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 2
  },
  verifyFieldHint: {
    textAlign: 'center', 
    width: '90%',
    fontSize: theme.typography.fontSize * 2
  },
  addVerticalSpace: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  addBottomSpace: {
    marginBottom: theme.spacing(3)
  }
});

export default styles;