import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import MUIGrid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  DataTypeProvider,
  EditingState,
  SortingState,
  SearchState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  SearchPanel,
  Toolbar,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import ls from 'local-storage';
import XLSX from 'xlsx';

import AudioPlayer from '../director/audioPlayerSmallComponent';

const styles = theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    left: 20,
    bottom: 20,
    right: 'auto',
    position: 'fixed',
  },
});

const getRowId = row => row._id;

const PaymentFormatter = ({ value }) => (
  <span>
    {value.payment1 ? value.payment1 : "Not Submitted"} {value.payment2}
  </span>
);

const PaymentEditor = ({ value, onValueChange }) => (
  <div>
    <Select
      input={<Input />}
      value={value.payment1}
      onChange={event => onValueChange({...value, payment1: event.target.value})}
      style={{ width: '100%' }}
    >
      <MenuItem value="Online">
        Online
      </MenuItem>
      <MenuItem value="School">
        School
      </MenuItem>
      <MenuItem value="Check">
        Check
      </MenuItem>
      <MenuItem value="">
        Not Submitted
      </MenuItem>
    </Select>
    {value.payment1 && (value.payment1 === "Check" || value.payment1 === "School") && (
      <TextField
        value={value.payment2}
        onChange={event => onValueChange({...value, payment2: event.target.value})}
        label="Check Number"
        fullWidth
        autoComplete="check"
      />
    )}
  </div>
)

const PaymentTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={PaymentFormatter}
    editorComponent={PaymentEditor}
    {...props}
  />
);

const BooleanFormatter = ({ value }) => <Chip label={value ? 'Yes' : 'No'} />;

const BooleanEditor = ({ value, onValueChange }) => (
  <Select
    input={<Input />}
    value={value ? 'Yes' : 'No'}
    onChange={event => onValueChange(event.target.value === 'Yes')}
    style={{ width: '100%' }}
  >
    <MenuItem value="Yes">
      Yes
    </MenuItem>
    <MenuItem value="No">
      No
    </MenuItem>
  </Select>
);

const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
);


const buildInstrumentList = (row) => {
  let {instruments, uploads, firstName, lastName, _id} = row;
  if(!uploads) uploads = {};

  return (<MUIGrid container>
    {
      instruments.map(instrument => (
        <MUIGrid key={instrument} item xs={12}>
          <MUIGrid spacing={1} justify="flex-start" alignItems="center" direction="row" container>
            <MUIGrid item style={{color: uploads[instrument] ? "#01750d" : "#8c0000"}}>
              {uploads[instrument] ? 
              <AudioPlayer info={{
                firstName,
                lastName,
                _id,
                instrument
              }} /> : "✘" }
            </MUIGrid>
            <MUIGrid item style={{color: uploads[instrument] ? "#01750d" : "#8c0000"}}>
              {instrument}
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      ))
    }
  </MUIGrid>);

}

const InstrumentsFormatter = ({ value }) => {
  return (
    <div>
      {buildInstrumentList(value)}
    </div>
  );
}

const InstrumentsTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={InstrumentsFormatter}
    editorComponent={InstrumentsFormatter}
    {...props}
  />
);



class StudentList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      columns: [
        { name: 'firstName', title: 'First Name' },
        { name: 'lastName', title: 'Last Name' },
        { name: 'director', title: 'Director', getCellValue: row => `${row.director.firstName} ${row.director.lastName}`},
        { name: 'school', title: 'School', getCellValue: row => row.director.school},
        {
          name: 'instruments',
          title: 'Instruments',
          getCellValue: row => row
        },
        { name: 'payment', title: 'Payment'},
        { name: 'email_verified', title: 'Email Verified'},
        { name: 'information_submitted', title: 'Info Submitted'},
        { name: 'payment_validated', title: 'Payment Valid'}
      ],
      sorting: [{ columnName: 'lastName', direction: 'asc' }],
      booleanColumns: ['information_submitted', 'email_verified', 'payment_validated'],
      rows: [],
    };
    this.changeSorting = sorting => this.setState({ sorting });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (changed) {
        const prevRow = rows.find(r => changed[r._id]);
        if (prevRow) {
          const newRow = {
            ...prevRow,
            ...changed[prevRow._id]
          };
          fetch('https://admin-api.coloradoaso.org/student', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'authorization': ls('admin-jwt')
            },
            body: JSON.stringify({
              updates: [newRow]
            })
          })
            .then(res => res.json())
            .then(res => {
              if (!res.error) {
                this.setState({
                  rows: res.rows
                })
              } else {
                this.setState({
                  error: `Something seems to have gone wrong(${res.error}).`
                });
              }
            });
        }
      }
      if (added) {
        fetch('https://admin-api.coloradoaso.org/student', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            news: added
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.rows
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
      if (deleted) {
        fetch('https://admin-api.coloradoaso.org/student', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            dels: deleted
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.rows
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
    };
    this.downloadXLSX = this.downloadXLSX.bind(this);
  }

  convertToArray(row) {
    return [
      row.firstName,
      row.lastName,
      row.email,
      row.director.firstName + " " + row.director.lastName,
      row.director.school,
      row.home,
      row.cell,
      row.address.address1 + " " +
      row.address.address2 + " " +
      row.address.city + " " +
      row.address.state + " " +
      row.address.zip,
      row.emergency.name + ": " +
      row.emergency.number + " - " +
      row.emergency.relationship,
      row.instruments.join(", "),
      (row.payment.payment1 || "Not Submitted") + " " + (row.payment.payment2||""),
      row.payment_validated ? "Yes" : "No",
    ];
  }

  downloadXLSX() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ['First Name', 'Last Name', 'Email', 'Director', 'School', 'Home', 'Cell', 'Address', 'Emergency Contact', 'Instruments', 'Payment'],
      ...this.state.rows.map(r => this.convertToArray(r))
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Directors");
    XLSX.writeFile(wb, 'students.xlsx');
  }

  componentDidMount() {
    fetch("https://admin-api.coloradoaso.org/students", {
      method: 'get',
      headers: {
        'authorization': ls('admin-jwt')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            rows: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  render() {
    const { rows, columns, sorting, booleanColumns, error } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.error ? true : false}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{error} Let Patrick know before trying to modify any data.</span>}
        />

        <Typography align="center" variant="h4" component="h4">
          Students
        </Typography>
        <div style={{maxWidth: '95vw'}}>

        
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <BooleanTypeProvider
            for={booleanColumns}
          />
          <InstrumentsTypeProvider for={["instruments"]} />
          <PaymentTypeProvider for={["payment"]} />
          <EditingState
            onCommitChanges={this.commitChanges}
            defaultEditingRowIds={[0]}
            columnExtensions={[
              {columnName: 'instruments', editingEnabled: false},
              {columnName: 'director', editingEnabled: false},
              {columnName: 'school', editingEnabled: false}
            ]}
          />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={10}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <SearchState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          
          <Table />
          
          <TableHeaderRow showSortingControls/>
          <TableColumnVisibility
            defaultHiddenColumnNames={['email_verified']}
          />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <TableEditRow />
          <TableEditColumn
            showEditCommand
            showDeleteCommand
          />
          <PagingPanel 
            pageSizes={[5, 10, 50, 100, 0]}
          />
          
          
        </Grid>
        <Fab onClick={this.downloadXLSX} color="secondary" aria-label="Edit" className={classes.fab}>
          <SaveAltIcon />
        </Fab>
        </div>
      </React.Fragment>
    );
  }
}

StudentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentList);