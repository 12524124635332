import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
// import Countdown from 'react-countdown-now';


import DirectorHeader from './header';
import StudentListComponent from './studentListComponent';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
  }
});

// const renderer = ({days, hours, completed}) => {
//   if (completed) {
//     return <span>Audition Window Closed</span>;
//   }else {
//     if(days > 1) {
//       return <span>{days} days</span>;
//     }else {
//       return <span>{hours} hours</span>;
//     }
//   }
// }

class DirectorDashboard extends React.Component {

  render() {
    const { user, classes } = this.props;
    return (
      <React.Fragment>
        <DirectorHeader />
        <Grid container justify="center">
          <Container className={classes.root}>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h5">
                      Welcome, {user.name}
                    </Typography>
                    <p>                   
                      Upload deadline: {new Date(user.deadline).toLocaleString()}. <br/>
                      { /* Time remaining: <Countdown renderer={renderer} date={new Date(user.deadline)} />. */}
                    </p>
                    <p>
                      To upload an audition for a student click on their name in the students table.
                      To view a student's information or to listen to an already uploaded audition click the expand icon at the beginning of each row.
                    </p>
                    <p>
                      An email will be sent to directors who still have pending uploads one week before the official deadline. 
                      Directors may request an extension to this deadline by contacting <a href="mailto:coloradoaso@gmail.com">coloradoaso@gmail.com</a>.
                    </p>
                    <p>
                      Audition files must be in MP3 format and 25MB or smaller. Please ensure auditions include all required materials and students do not speak during or before the audition.
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={7}>
                <StudentListComponent user={user} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </React.Fragment>
    );
  }

}

DirectorDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DirectorDashboard);