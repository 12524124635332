import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';


import ls from 'local-storage';
import { DropzoneArea } from 'material-ui-dropzone';

const styles = theme => ({
  modalContainer: {
    padding: theme.spacing(2)
  }
});



class UploadComponent extends React.Component {

  constructor(props) {
    super(props);
    let { student } = this.props;
    this.state = {
      file: null,
      instrument: "",
      uploading: false
    };
    if(student && student.instruments.length === 1) this.state.instrument = student.instruments[0];
    this.setFile = this.setFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleInstrument = this.handleInstrument.bind(this);
  }

  componentDidUpdate(prevProps) {
    let { student } = this.props;
    if(student && student.instruments.length === 1 && this.state.instrument !== student.instruments[0]) this.setState({
      instrument: student.instruments[0]
    });
  }

  uploadFile() {
    this.setState({
      uploading: true
    });
    this.postFile('https://api.coloradoaso.org/student-upload', this.state.file, this.props.student._id, this.state.instrument).then(res => {
      this.setState({
        uploading: false,
        instrument: "",
        file: null
      });
      this.props.refresh();
    }).catch(err => {
      this.setState({
        uploading: false
      });
    });
  }

  postFile(url, file, id, instrument) {
    const data = new FormData();
    data.append('file', file);
    data.append('id', id);
    data.append('instrument', instrument);
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'director': ls('director-jwt')
      },
      credentials: "same-origin", // include, *same-origin, omit
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: data, // body data type must match "Content-Type" header
    })
    .then(response => response.json()); // parses response to JSON
  }

  setFile(file) {
    this.setState({
      file: file[0]
    });
  }
  
  handleInstrument(event) {
    this.setState({
      instrument: event.target.value
    });
  };

  render() {
    const { student, doClose } = this.props;
    const { file, instrument } = this.state;
    return (
      <Dialog open={student !== null}>
        {student !== null ?
        (<React.Fragment>
          <DialogTitle>Audition Upload</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {student.firstName} {student.lastName}
              </Grid>
              {student.instruments.length > 1 ? null : (<Grid item xs={12}>
                {instrument}
              </Grid>) }
              <Grid item xs={12}>
                { student.instruments.length > 1 ? (
                  <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor={"ins"}>
                      Instrument
                    </InputLabel>
                    <Select
                      value={instrument}
                      onChange={this.handleInstrument}
                      inputProps={{
                        name: 'instrument',
                        id: 'ins',
                      }}
                    >
                      <MenuItem value="">
                        <em>Select an instrument</em>
                      </MenuItem>
                      {
                        student.instruments.map(ins => (
                          <MenuItem key={ins} value={ins}>{ins}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>) : null
                }
              </Grid>
              <Grid item xs={12}>
                <Collapse in={instrument.length > 0}>
                  {student.solos[instrument] ? <div>Solo: {student.solos[instrument].name} by {student.solos[instrument].composer}</div>: null}
                  <DropzoneArea 
                    filesLimit={1}
                    maxFileSize={25000000}
                    acceptedFiles={["audio/mpeg", "audio/mp3"]}
                    dropzoneText={"Drag and drop an MP3 file here or click to upload"}
                    onChange={this.setFile}
                    showPreviewsInDropzone={false}
                  />
                  {file ? file.name : ""}
                </Collapse>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={doClose}
              >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={this.state.file === null || this.state.uploading}
              onClick={this.uploadFile}
              >
              {this.state.uploading ? <CircularProgress /> : "Upload"}
            </Button>
          </DialogActions>
        </React.Fragment>
        ):<div></div>}
        
      </Dialog>
      );
  }
}

export default withStyles(styles)(UploadComponent);