import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //width: "100%",
    },
  },
};

const styles = theme => ({

});

class TagSelect extends React.PureComponent {
  /* constructor(props) {
    super(props);
  } */

  render() {
    const {
      classes,
      value,
      options,
      onChange,
      id,
      label,
      error,
      helperText
    } = this.props;
    return (
      <FormControl error={error} fullWidth className={classes.formControl}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          fullWidth
          multiple
          value={value}
          onChange={onChange}
          input={<Input id={id} />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {options.map(opt => opt.placeholder ? (
            <MenuItem disabled key={opt.value} value="">
              {opt.value}
            </MenuItem>
          ) : (
            <MenuItem key={opt.value} value={opt.value}>
              <Checkbox checked={value.indexOf(opt.value) > -1} />
              <ListItemText primary={opt.value} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }

}

TagSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default withStyles(styles)(TagSelect);