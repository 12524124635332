import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DateTimePicker from 'react-datetime-picker';
import {
  DataTypeProvider,
  EditingState,
  SearchState,
  PagingState,
  SortingState,
  IntegratedPaging,
  IntegratedFiltering,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  SearchPanel,
  Toolbar,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import ls from 'local-storage';
import XLSX from 'xlsx';

const styles = theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    left: 20,
    bottom: 20,
    right: 'auto',
    position: 'fixed',
  }
});

const getRowId = row => row._id;

const TimeFormatter = ({ value }) => <div>{new Date(value).getTime() > 0 ? new Date(value).toLocaleString() : "Never"}</div>

const TimeEditor = ({ value, onValueChange }) => {
  return <DateTimePicker value={new Date(value)} onChange={date => onValueChange(date.toString())} />
}

const TimeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={TimeFormatter}
    editorComponent={TimeEditor}
    {...props}
  />
);

const BooleanFormatter = ({ value }) => <Chip label={value ? 'Yes' : 'No'} />;

const BooleanEditor = ({ value, onValueChange }) => (
  <Select
    input={<Input />}
    value={value ? 'Yes' : 'No'}
    onChange={event => onValueChange(event.target.value === 'Yes')}
    style={{ width: '100%' }}
  >
    <MenuItem value="Yes">
      Yes
    </MenuItem>
    <MenuItem value="No">
      No
    </MenuItem>
  </Select>
);

const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
);

class TeacherList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      columns: [
        { name: 'firstName', title: 'First' },
        { name: 'lastName', title: 'Last' },
        { name: 'school', title: 'School' },
        { name: 'email', title: 'Email' },
        { name: 'cell', title: 'Cell' },
        { name: 'work', title: 'Work' },
        { name: 'email_verified', title: 'Email Verified?' },
        { name: 'verified', title: 'Account Verified?' },
        { name: 'nafme', title: 'NAfME' },
        { name: 'first_seen', title: 'First Seen', getCellValue: row => new Date(parseInt(row._id.substring(0, 8), 16) * 1000)},
        { name: 'last_seen', title: 'Last Seen' },
        { name: 'submissionExpires', title: 'Deadline' }
      ],
      booleanColumns: ['verified', 'email_verified'],
      sorting: [{ columnName: 'lastName', direction: 'asc' }],
      rows: [],
    };
    this.changeSorting = sorting => this.setState({ sorting });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (changed) {
        const prevRow = rows.find(r => changed[r._id]);
        if (prevRow) {
          const newRow = {
            ...prevRow,
            ...changed[prevRow._id]
          };
          fetch('https://admin-api.coloradoaso.org/director', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'authorization': ls('admin-jwt')
            },
            body: JSON.stringify({
              updates: [newRow]
            })
          })
            .then(res => res.json())
            .then(res => {
              if (!res.error) {
                this.setState({
                  rows: res.directors
                })
              } else {
                this.setState({
                  error: `Something seems to have gone wrong(${res.error}).`
                });
              }
            });
        }
      }
      if (added) {
        fetch('https://admin-api.coloradoaso.org/director', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            news: added
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.directors
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
      if (deleted) {
        fetch('https://admin-api.coloradoaso.org/director', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            dels: deleted
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.directors
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
    };
    this.downloadXLSX = this.downloadXLSX.bind(this);
  }

  convertToArray(row) {
    return [
      row.firstName,
      row.lastName,
      row.school,
      row.email,
      row.cell,
      row.work,
      row.email_verified? "Y": "N",
      row.verified? "Y": "N",
      row.nafme
    ];
  }

  downloadXLSX() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ['First Name', 'Last Name', 'School', 'Email', 'Cell', 'Work', "Email Verified?", "NAfME Verified?", "NAfME"],
      ...this.state.rows.map(r => this.convertToArray(r))
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Directors");
    XLSX.writeFile(wb, 'directors.xlsx');
  }

  componentDidMount() {
    fetch("https://admin-api.coloradoaso.org/directors", {
      method: 'get',
      headers: {
        'authorization': ls('admin-jwt')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            rows: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  render() {
    const { rows, columns, sorting, booleanColumns, error } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.error ? true : false}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{error} Let Patrick know before trying to modify any data.</span>}
        />

        <Typography align="center" variant="h4" component="h4">
          Directors
        </Typography>
        <div style={{maxWidth: '95vw'}}>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
          >
            <BooleanTypeProvider
              for={booleanColumns}
            />
            <TimeTypeProvider
              for={["submissionExpires", 'last_seen', 'first_seen']}
            />
            <EditingState
              onCommitChanges={this.commitChanges}
              defaultEditingRowIds={[0]}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <SearchState />
            <PagingState
              defaultCurrentPage={0}
              defaultPageSize={10}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            <Table
              columnExtensions={[
                { columnName: 'submissionExpires', width: 260 },
                { columnName: 'last_seen', width: 170 }
              ]}
            />
            
            <TableHeaderRow showSortingControls/>
            <TableColumnVisibility
              defaultHiddenColumnNames={['submissionExpires', 'first_seen', 'last_seen', 'email_verified', 'email', 'cell', 'work']}
            />
            <Toolbar />
            <SearchPanel />
            <ColumnChooser />
            <TableEditRow />
            <TableEditColumn
              showAddCommand
              showEditCommand
              showDeleteCommand
            />
            <PagingPanel 
              pageSizes={[5, 10, 50, 100, 0]}
            />
            
          </Grid>
          <Fab onClick={this.downloadXLSX} color="secondary" aria-label="Edit" className={classes.fab}>
            <SaveAltIcon />
          </Fab>
        </div>
        
      </React.Fragment>
    );
  }
}

TeacherList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeacherList);