import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';

import Info from '@material-ui/icons/Info';


import dirVal from '../../data/validation';
import styles from '../../theme';
import PhoneInput from '../../layouts/form_elements/PhoneInput';
import TagSelect from '../../layouts/form_elements/TagSelect';

import instruments from '../../data/instruments';

const solos = instruments.reduce((solos, ins) => {
  solos[ins.value] = ins.solo;
  return solos;
}, {});

class StudentInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      directors: [],
      school: "",
      redirect: "",
      validated: false,
      loading: true,
      showResend: false,
      resendSent: false,

      //Info Form Vars
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      cell: "(   )    -    ",
      cellError: "",
      home: "(   )    -    ",
      homeError: "",
      address1: "",
      address1Error: "",
      address2: "",
      address2Error: "",
      city: "",
      cityError: "",
      gstate: "Colorado",
      gstateError: "",
      zip: "",
      zipError: "",
      eName: "",
      eNameError: "",
      eNumber: "(   )    -    ",
      eNumberError: "",
      eRelation: "",
      eRelationError: "",
      eEmail: "",
      eEmailError: "",

      inss: [],
      inssError: "",
      insSolos: {},
      insSolosError: {},

      payment1: "School",
      payment2: "",
      payment2Error: "",
      showPayment: false,

    }
    this.validateInfo = this.validateInfo.bind(this);
    this.submitAll = this.submitAll.bind(this);
    this.hasError = this.hasError.bind(this);
    this.handleInstrument = this.handleInstrument.bind(this);
    this.unvalidate = this.unvalidate.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handlePayment2 = this.handlePayment2.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    this.showPayment = this.showPayment.bind(this);
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  resendEmail() {
    this.postData("https://api.coloradoaso.org/student-resend", { email: this.state.email }).then(res => {
      if (res.error) return console.log(res);
      this.setState({
        resendSent: true
      });
    }).catch(err => {
      console.log(err);
    });

  }

  unvalidate() {
    this.setState({
      validated: false
    });
  }

  handlePayment(event) {
    this.setState({
      payment1: event.target.value,
      payment2: '',
      payment2Error: ''
    });
  }

  handlePayment2(event) {
    this.setState({
      payment2: event.target.value,
      payment2Error: event.target.value.length > 0 ? '' : this.state.payment2Error
    });
  }


  getSuggestions(suggestions, value, { showEmpty = false } = {}) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength < 2 && !showEmpty
      ? []
      : suggestions.filter(suggestion => {
        const display = `${suggestion.firstName} ${suggestion.lastName} - ${suggestion.school}`;
        const keep =
          count < 4 && display.toLowerCase().indexOf(inputValue) >= 0;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  }

  renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(`${suggestion.firstName} ${suggestion.lastName} - ${suggestion.school}`) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion._id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {`${suggestion.firstName} ${suggestion.lastName} - ${suggestion.school}`}
      </MenuItem>
    );
  }

  componentDidMount() {
    this.getData("https://api.coloradoaso.org/directors").then(res => {
      this.setState({
        loading: false,
        directors: res
      });
    }).catch(err => {
      console.log(err);
      this.setState({
        infoError: "Error loading directors. Please try again later."
      })
    });
  }

  getData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
    })
      .then(response => response.json()); // parses response to JSON
  }

  hasError(value) {
    if (this.state[`${value}Error`].length > 0) return true;
    return false;
  }

  showPayment() {
    if (this.validateInstruments()) this.setState({
      showPayment: true
    });
  }

  validateEmail(event) {
    const email = event.target.value;
    this.postData("https://api.coloradoaso.org/student-email", { email }).then(res => {

      if (res.email && !res.email.email_verified) {
        this.setState({
          showResend: true
        });
      }
      /*
      this.setState(Object.assign({
        loading: false
      }, res));*/
    }).catch(err => {
      console.log(err);
    });
  }

  validateInstruments() {
    let valid = true;
    const update = { ...this.state };
    if (this.state.inss.length === 0) {
      valid = false;
      update.inssError = "Please select an instrument";
    } else { update.inssError = ""; }

    for (const ins of this.state.inss) {
      if (solos[ins]) {
        if (!this.state.insSolos[ins]) {
          valid = false;
          update.insSolosError[`${ins}Name`] = "Enter a valid title";
          update.insSolosError[`${ins}Composer`] = "Enter a valid composer";
        } else {
          if (!dirVal.validSolo(this.state.insSolos[ins].name)) {
            valid = false;
            update.insSolosError[`${ins}Name`] = "Enter a valid title";
          } else { update.insSolosError[`${ins}Name`] = ""; }
          if (!dirVal.validSolo(this.state.insSolos[ins].composer)) {
            valid = false;
            update.insSolosError[`${ins}Composer`] = "Enter a valid composer";
          } else { update.insSolosError[`${ins}Composer`] = ""; }
        }
      }
    }

    this.setState(update);
    return valid;
  }

  validateForm() {
    let valid = true;
    const update = {};
    if (!dirVal.validName(this.state.firstName)) {
      valid = false;
      update.firstNameError = "Enter a first name";
    } else { update.firstNameError = ""; }
    if (!dirVal.validName(this.state.lastName)) {
      valid = false;
      update.lastNameError = "Enter a last name";
    } else { update.lastNameError = ""; }
    if (!dirVal.validPhone(this.state.cell)) {
      valid = false;
      update.cellError = "Enter a valid phone number";
    } else { update.cellError = ""; }
    if (!dirVal.validPhone(this.state.home) && this.state.home.replace(/\D/g, '').length !== 0) {
      valid = false;
      update.homeError = "Enter a valid phone number";
    } else { update.homeError = ""; }
    if (!dirVal.validEmail(this.state.email)) {
      valid = false;
      update.emailError = "Enter a valid email address";
    } else { update.emailError = ""; }
    if (!dirVal.validAddress(this.state.address1)) {
      valid = false;
      update.address1Error = "Enter a valid address";
    } else { update.address1Error = ""; }
    if (!dirVal.validZip(this.state.zip)) {
      valid = false;
      update.zipError = "Enter a valid zip code";
    } else { update.zipError = ""; }
    if (!dirVal.validState(this.state.gstate)) {
      valid = false;
      update.gstateError = "Enter a valid state";
    } else { update.gstateError = ""; }
    if (!dirVal.validCity(this.state.city)) {
      valid = false;
      update.cityError = "Enter a valid city"
    } else { update.cityError = ""; }
    if (!dirVal.validName(this.state.eName)) {
      valid = false;
      update.eNameError = "Enter a valid contact name";
    } else { update.eNameError = ""; }
    if (!dirVal.validPhone(this.state.eNumber)) {
      valid = false;
      update.eNumberError = "Enter a valid contact number";
    } else { update.eNumberError = ""; }
    if (!dirVal.validEmail(this.state.eEmail)) {
      valid = false;
      update.eEmailError = "Enter a valid email address";
    } else { update.eEmailError = ""; }
    if (!dirVal.validName(this.state.eRelation)) {
      valid = false;
      update.eRelationError = "Enter a valid relationship";
    } else { update.eRelationError = ""; }

    this.setState(update);
    return valid;
  }

  validatePayment() {
    let valid = true;
    const update = {}
    const {
      payment1,
      payment2
    } = this.state;
    if (payment1 === "Check" && payment2.length === 0) {
      valid = false;
      update.payment2Error = "Enter the check number used";
    } else update.payment2Error = "";

    this.setState(update);
    return valid;
  }

  schoolToId() {
    for (const director of this.state.directors) {
      if (this.state.school === `${director.firstName} ${director.lastName} - ${director.school}`)
        return director._id;
    }
    return null;
  }



  submitAll() {
    if (this.validateForm() && this.validateInstruments() && this.validatePayment()) {
      const body = {
        director: this.schoolToId(),
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        cell: this.state.cell.replace(/[^0-9]/g, ''),
        home: this.state.home.replace(/[^0-9]/g, ''),
        address: {
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.gstate,
          zip: this.state.zip
        },
        emergency: {
          name: this.state.eName,
          number: this.state.eNumber.replace(/[^0-9]/g, ''),
          relationship: this.state.eRelation,
          email: this.state.eEmail
        },
        payment: {
          payment1: this.state.payment1,
          payment2: this.state.payment2
        },
        instruments: this.state.inss,
        solos: this.state.insSolos
      };
      this.setState({
        loading: true
      });
      this.postData("https://api.coloradoaso.org/student", body).then(res => {

        if (res.errors) {
          if (res.errors === "email");
          this.setState({
            infoError: "This email is already taken. Please verify this email or wait 24 hours for the verification to expire.",
            emailError: "Email taken",
            loading: false
          });
        } else {
          this.setState({
            redirect: res._id
          });
        }
      }).catch(err => {
        this.setState({
          infoError: "There was an error. Please try again later."
        });
      });
    }
  }

  validateInfo() {
    if (this.validateForm()) {
      this.setState({
        validated: true
      });
    }
  }

  postData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => response.json()); // parses response to JSON
  }

  handleSchool = name => school => {
    this.setState({
      school
    });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      if (this.state[`${name}Error`].length > 0) {
        this.validateForm();
      }
    });
  };

  handleInstrument(event) {

    //Limit to three
    if (event.target.value.length > 3) {
      return;
    }
    this.setState({
      inss: event.target.value
    });
  }

  handleSoloName = ins => event => {
    this.setState({
      insSolos: {
        ...this.state.insSolos,
        [ins]: {
          name: event.target.value,
          composer: (this.state.insSolos[ins] || {}).composer || ""
        }
      }
    });
  }

  handleSoloComposer = ins => event => {
    this.setState({
      insSolos: {
        ...this.state.insSolos,
        [ins]: {
          composer: event.target.value,
          name: (this.state.insSolos[ins] || {}).name || ""
        }
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {
      validated,
      school,
      firstName,
      lastName,
      email,
      cell,
      home,
      address1,
      address2,
      city,
      gstate,
      zip,
      eName,
      eNumber,
      eEmail,
      eRelation,
      inss,
      payment1,
      payment2,
      showPayment
    } = this.state;

    if (this.state.redirect.length > 0) {
      return <Redirect to={`/student-registration/success/${this.state.redirect}`} />
    }

    return (
      <React.Fragment>
        <Typography variant="body2" gutterBottom>
          {this.state.infoError}
        </Typography>
        <Collapse in={!validated}>
          <Typography variant="h6" gutterBottom>
            School and Director
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                <Grid item>
                  <Info />
                </Grid>
                <Grid item xs>
                  <Typography>If your school or director is not included in this list please ensure your director has registered.</Typography>
                  {/*<Typography>Student registration opens on September 18th, 2023</Typography>*/}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.addVerticalSpace}>
              <Downshift onChange={this.handleSchool('school')} id="downshift-school">
                {({
                  getInputProps,
                  getItemProps,
                  getLabelProps,
                  getMenuProps,
                  highlightedIndex,
                  inputValue,
                  isOpen,
                  selectedItem,
                }) => {
                  const { onBlur, onFocus, ...inputProps } = getInputProps({
                    placeholder: 'Search for your director',
                  });

                  return (
                    <div className={classes.container}>
                      {this.renderInput({
                        fullWidth: true,
                        classes,
                        label: 'School - Director',
                        InputLabelProps: getLabelProps({ shrink: true }),
                        InputProps: { onBlur, onFocus },
                        inputProps,
                      })}

                      <div {...getMenuProps()}>
                        {isOpen ? (
                          <Paper
                            style={{
                              position: 'relative',
                              zIndex: 1,
                              left: 0,
                              right: 0,
                            }}
                            square
                          >
                            {this.getSuggestions(this.state.directors, inputValue).map((suggestion, index) =>
                              this.renderSuggestion({
                                suggestion,
                                index,
                                itemProps: getItemProps({ item: `${suggestion.firstName} ${suggestion.lastName} - ${suggestion.school}` }),
                                highlightedIndex,
                                selectedItem,
                              }),
                            )}
                          </Paper>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              </Downshift>

            </Grid>
          </Grid>

        </Collapse>
        <Collapse in={school.length > 0 && !validated}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Student
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    value={firstName}
                    onChange={this.handleChange('firstName')}
                    helperText={this.state.firstNameError}
                    error={this.hasError('firstName')}
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    value={lastName}
                    onChange={this.handleChange('lastName')}
                    helperText={this.state.lastNameError}
                    error={this.hasError('lastName')}
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    value={email}
                    onChange={this.handleChange('email')}
                    onBlur={this.validateEmail}
                    helperText={this.state.emailError}
                    error={this.hasError('email')}
                    id="email"
                    name="email"
                    label="Email Address"
                    fullWidth
                    disabled={this.state.loading}
                  />
                  <Collapse in={this.state.showResend}>
                    <Typography variant="h6" gutterBottom>
                      Resend Email?
                    </Typography>
                    It looks like this email has been registered, but not verified.
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.resendEmail}
                      disabled={this.state.resendSent}
                    >
                      {!this.state.resendSent ? 'Resend Email' : 'Email Sent'}
                    </Button>
                  </Collapse>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PhoneInput
                    value={cell}
                    onChange={this.handleChange('cell')}
                    id="cell"
                    helperText={this.state.cellError}
                    error={this.hasError('cell')}
                    label="Cell Number"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PhoneInput
                    value={home}
                    onChange={this.handleChange('home')}
                    id="home"
                    helperText={this.state.homeError}
                    error={this.hasError('home')}
                    label="Home Number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Address
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    required
                    value={address1}
                    onChange={this.handleChange('address1')}
                    helperText={this.state.address1Error}
                    error={this.hasError('address1')}
                    id="address1"
                    name="address1"
                    label="Address Line 1"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required={false}
                    value={address2}
                    onChange={this.handleChange('address2')}
                    helperText={this.state.address2Error}
                    error={this.hasError('address2')}
                    id="address2"
                    name="address2"
                    label="Address Line 2"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    value={city}
                    onChange={this.handleChange('city')}
                    helperText={this.state.cityError}
                    error={this.hasError('city')}
                    id="city"
                    name="city"
                    label="City"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    value={gstate}
                    onChange={this.handleChange('gstate')}
                    helperText={this.state.gstateError}
                    error={this.hasError('gstate')}
                    id="state"
                    name="state"
                    label="State"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    value={zip}
                    onChange={this.handleChange('zip')}
                    helperText={this.state.zipError}
                    error={this.hasError('zip')}
                    id="zip"
                    name="zip"
                    label="Zip"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Emergency & Parent/Guardian Contact
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <TextField
                    required
                    value={eName}
                    onChange={this.handleChange('eName')}
                    helperText={this.state.eNameError}
                    error={this.hasError('eName')}
                    id="eName"
                    name="Emergency Name"
                    label="Full Name"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    required
                    value={eEmail}
                    onChange={this.handleChange('eEmail')}
                    helperText={this.state.eEmailError}
                    error={this.hasError('eEmail')}
                    id="eEmail"
                    name="eEmail"
                    label="Email Address"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <PhoneInput
                    value={eNumber}
                    onChange={this.handleChange('eNumber')}
                    id="eNumber"
                    helperText={this.state.eNumberError}
                    error={this.hasError('eNumber')}
                    label="Number"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    required
                    value={eRelation}
                    onChange={this.handleChange('eRelation')}
                    helperText={this.state.eRelationError}
                    error={this.hasError('eRelation')}
                    id="eRelation"
                    name="Emergency Relation"
                    label="Relationship"
                    fullWidth
                    disabled={this.state.loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.validateInfo}
              className={classes.button}
              disabled={this.state.loading}
            >
              {this.state.loading ? "..." : "Next"}
            </Button>
          </div>
        </Collapse>
        <Collapse in={validated && !showPayment}>
          <Grid container spacing={1}>

            <Typography variant="h6" gutterBottom>
              Instrument
            </Typography>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                <Grid item>
                  <Info />
                </Grid>
                <Grid item xs>
                  <Typography>Select up to three instruments. Only one audition fee is required.</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TagSelect
                options={instruments}
                onChange={this.handleInstrument}
                id="instruments"
                value={inss}
                label="Instrument(s) (maximum 3)"
                helperText={this.state.inssError}
                error={this.hasError("inss")}
              />
            </Grid>
            <Collapse className={classes.addVerticalSpace} in={inss.filter(i => solos[i]).length > 0}>
              <Typography variant="h6" gutterBottom>
                Solos
              </Typography>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Info />
                  </Grid>
                  <Grid item xs>
                    <Typography>One or more of your instruments require a solo audition. Please provide the title and composer for each solo.</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {
                  inss.filter(i => solos[i]).map(ins => (
                    <Grid className={classes.addBottomSpace} item xs={12} key={ins}>
                      <Typography variant="subtitle1">
                        <b>{ins} Solo:</b>
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            required
                            value={(this.state.insSolos[ins] || {}).name || ""}
                            onChange={this.handleSoloName(ins)}
                            helperText={this.state.insSolosError[`${ins}Name`]}
                            error={(this.state.insSolosError[`${ins}Name`] || "").length > 0}
                            id={`solo-${ins}-name`}
                            name={`solo-${ins}-name`}
                            label={`Title`}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            required
                            value={(this.state.insSolos[ins] || {}).composer || ""}
                            onChange={this.handleSoloComposer(ins)}
                            helperText={this.state.insSolosError[`${ins}Composer`]}
                            error={(this.state.insSolosError[`${ins}Composer`] || "").length > 0}
                            id={`solo-${ins}-composer`}
                            name={`solo-${ins}-composer`}
                            label={`Composer`}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                  ))
                }
              </Grid>
            </Collapse>
          </Grid>
          <div className={classes.buttons}>
            <Button onClick={this.unvalidate} className={classes.button}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.showPayment}
              className={classes.button}
              disabled={this.state.loading}
            >
              {this.state.loading ? "..." : "Next"}
            </Button>
          </div>
        </Collapse>
        <Collapse in={validated && showPayment}>
          <Typography variant="h6" gutterBottom>
            Payment Information
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth component="fieldset" className={classes.formControl}>
                <RadioGroup aria-label="payment" name="payment1" value={payment1} onChange={this.handlePayment}>
                  {/*<FormControlLabel value="Online" control={<Radio />} label="Online" />
                  <Collapse in={this.state.payment1 === "Online"}>
                    <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Info />
                      </Grid>
                      <Grid item xs>
                        <Typography>Audition fees may be payed online <a target="_blank" rel="noopener noreferrer" href="https://cmeaonline.org/all-state-groups/colorado-all-state-high-school-orchestra-audition-payment/">here</a>.</Typography>
                      </Grid>
                    </Grid>
                  </Collapse>
                  <FormControlLabel value="Check" control={<Radio />} label="Check" />
                  <Collapse in={payment1 === "Check"}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          value={payment2}
                          onChange={this.handlePayment2}
                          id="payment2"
                          name="payment2"
                          label="Check Number"
                          helperText={this.state.payment2Error}
                          error={this.state.payment2Error.length > 0}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Collapse> */}
                  <FormControlLabel value="School" control={<Radio />} label="My school is paying" />
                  <Collapse in={this.state.payment1 === "School"}>
                    <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Info />
                      </Grid>
                      <Grid item xs>
                        <Typography>For CASO 2024, all audition and participation fees will go through your director.</Typography>
                      </Grid>
                    </Grid>
                  </Collapse>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.submitAll}
              className={classes.button}
              disabled={this.state.loading}
            >
              {this.state.loading ? "..." : "Next"}
            </Button>
          </div>
        </Collapse>
      </React.Fragment>
    );
  }
}

StudentInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentInfo);