// eslint-disable-next-line
const email_regex = /^[a-zA-Z0-9_+]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const phone_regex = /^\d{10}$/;
const zip_regex = /^\d{5}$/;
const nafme_regex = /^\d{5,7}$/;

const v = {
  validEmail: (v) => {
    return email_regex.test(v);
  },
  validPhone: (v) => {
    const stripped = v.replace(/[^0-9]/g, '');
    return phone_regex.test(stripped);
  },
  validName: (v) => {
    return v.length >= 3 && v.length <= 36;
  },
  validNafme: (v) => {
    return nafme_regex.test(v);
  },
  validSchool: (v) => {
    return v.length >= 6 && v.length <= 100;
  },
  validAddress: (v) => {
    return v.length >= 3 && v.length <= 35;
  },
  validZip: (v) => {
    return zip_regex.test(v);
  },
  validState: (v) => {
    return v.length >= 2 && v.length <= 13
  },
  validCity: (v) => {
    return v.length >= 2 && v.length <= 30;
  },
  validSolo: (v) => {
    return v.length >= 2 && v.length <= 60;
  }
}

export default v;

//^(?!0+$)