import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import AdminHeader from './adminheader';
import StudentList from './studentListComponent';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    width: 'auto'
  },
});

class AdminStudents extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AdminHeader />
        <Grid container justify="center">
          <Paper className={classes.root}>
            <StudentList/>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }

}

AdminStudents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminStudents);