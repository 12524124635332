import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import ls from 'local-storage';
import { Redirect, Link } from "react-router-dom";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  footer: {
    marginTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(6)}px 0`,
  },
});


class AdminHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: null
    }
    this.logout = this.logout.bind(this);
  }

  logout() {
    ls.remove('admin-jwt');
    this.setState({
      redirect: '/caso-admin/login'
    });
  }

  redir(loc) {
    this.setState({
      redirect: loc
    });
  }

  render() {
    if(this.state.redirect) return <Redirect to={this.state.redirect} />
    const { classes } = this.props;
    return (
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            CASO Admin
          </Typography>
          <Button component={Link} to="/caso-admin">Dashboard</Button>
          <Button component={Link} to="/caso-admin/directors">Directors</Button>
          <Button component={Link} to="/caso-admin/students">Students</Button>
          <Button component={Link} to="/caso-admin/schools">Schools</Button>
          <Button component={Link} to="/caso-admin/auditions">Auditions</Button>
          <Button component={Link} to="/caso-admin/adjudicators">Adjudicators</Button>
          <Button component={Link} to="/caso-admin/emails">Emails</Button>
          <Button onClick={this.logout} color="primary" variant="outlined">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

}

AdminHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminHeader);