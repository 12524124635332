import React, { Component } from 'react';
import ReactAudioPlayer from 'react-audio-player';

import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';


import IconButton from '@material-ui/core/IconButton';

import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

const styles = theme => ({
  fabPlay: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: 0,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
});

class AudioPlayer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cur: 0,
      dur: 0,
      playing: false,
    }
    this.togglePlaying = this.togglePlaying.bind(this);
    this.resetPosition = this.resetPosition.bind(this);
    this.ts = new Date().getTime();
  }

  togglePlaying() {
    if(!this.rap) return;
    if(this.state.playing) this.rap.audioEl.pause();
    else this.rap.audioEl.play(); 
  }

  resetPosition() {
    if(!this.rap) return;
    this.rap.audioEl.currentTime = 0;
  }
  
  getPercent() {
    const { cur, dur } = this.state;
    if(dur === 0) return 0;
    return cur / dur * 100;
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.info) return;
    if(!this.props.info) return;
    const oldId = prevProps.info._id;
    const oldIns = prevProps.info.instrument;
    const newId = this.props.info._id;
    const newIns = this.props.info.instrument;
    if(oldId !== newId || oldIns !== newIns) {
      this.setState({
        cur: 0,
        dur: 0,
        playing: false,
      });
    }
  }

  render () {
    const {
      info, classes
    } = this.props;
    const {
      playing,
    } = this.state;
    if(!info._id) return null;
    return (
      <div className={classes.wrapper}>
        <CircularProgress size={30} variant="static" value={this.getPercent() || 100} className={classes.fabPlay} />
        <IconButton
          size="small"
          onClick={this.togglePlaying}
        >
          {playing ? <PauseIcon/> : <PlayIcon/> }
        </IconButton>
        <ReactAudioPlayer
          src={`https://api.coloradoaso.org/student-download/${info._id}/${info.instrument}?ts=${this.ts}.mp3`}
          autoPlay={false}
          ref={(element) => { this.rap = element; }}
          listenInterval={300}
          onListen={(time) => { this.setState({cur: time}) }}
          onCanPlay={(e) => { this.setState({dur: e.target.duration}) }}
          onPlay={(e) => { this.setState({playing: true}) }}
          onPause={(e) => { this.setState({playing: false}) }}
          onEnded={this.resetPosition}
        />
      </div>
    );    
  }
}


export default withStyles(styles)(AudioPlayer);
