import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import WideForm from '../../layouts/wideform';
import Form from '../../layouts/form';

import StudentInfo from './studentInfo';
import Verify from './verify';
import Review from './review';
import Complete from './complete';

const steps = ['Information','Verify', 'Review'];

class StudentRegistration extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Switch>

          <Redirect exact from="/student-registration" to="/student-registration/information" />
          <Redirect exact from="/student-registration/verify" to="/student-registration/information" />
          <Redirect exact from="/student-registration/review" to="/student-registration/information" />
          <Redirect exact from="/student-registration/success" to="/student-registration/information" />

          <Route from="/student-registration/verify/:id" render={props => <Form title="Student Registration" CurrentStep={Verify} steps={steps} step_index={1} {...props} />} />
          <Route from="/student-registration/review/:id" render={props => <Form title="Student Registration" CurrentStep={Review} steps={steps} step_index={2} {...props} />} />
          <Route from="/student-registration/success/:id" render={props => <Form title="Student Registration" CurrentStep={Complete} steps={steps} step_index={3} {...props} />} />


          <Route render={props => <WideForm title="Student Registration" CurrentStep={StudentInfo} steps={steps} step_index={0} {...props} />} />
          
        </Switch>
      </React.Fragment>
    );
  }

}

export default StudentRegistration;