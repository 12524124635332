import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from '../../theme';

class Complete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  componentDidMount() {
    this.postData("https://api.coloradoaso.org/student-id", { _id: this.props.match.params.id }).then(res => {
      this.setState(Object.assign({
        loading: false
      }, res));
    }).catch(err => {
      console.log(err);
    });
  }

  postData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => response.json()); // parses response to JSON
  }


  render() {
    //const { classes } = this.props;
    if(this.state.loading) return <div>Loading...</div>;
    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          Registration Complete!
        </Typography>
        <Typography variant="subtitle1">
          Thank you {this.state.firstName} {this.state.lastName} for registering for the Colorado All State Orchestra! Your director can now upload audition files on your behalf.
        </Typography>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(Complete);
