import React from 'react';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';


import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import WaveSurfer from 'wavesurfer.js';

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      cur: 0,
      dur: 0
    }
    this.ts = new Date().getTime();
    this.onPause = this.onPause.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.togglePlaying = this.togglePlaying.bind(this);
    this.handleVolume = this.handleVolume.bind(this);
    this.onSeek = this.onSeek.bind(this);
    this.onReady = this.onReady.bind(this);
  }

  componentDidMount() {
    const {audition} = this.props;
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      height: 80,
      normalize: true,
      barWidth: 2
    });
    this.wavesurfer.load(`https://api.coloradoaso.org/student-download/${audition.student}/${audition.instrument}?ts=${this.ts}.mp3`)
    this.wavesurfer.on('play', this.onPlay);
    this.wavesurfer.on('pause', this.onPause);
    this.wavesurfer.on('audioprocess', this.onSeek);
    this.wavesurfer.on('ready', this.onReady);
  }

  onSeek(time) {
    this.setState({
      cur: time
    });
  }

  onReady() {
    this.setState({
      dur: this.wavesurfer.getDuration()
    });
  }

  onPlay() {
    this.setState({
      playing: true
    });
  }

  onPause() {
    this.setState({
      playing: false
    });
  }

  togglePlaying() {
    this.wavesurfer.playPause();
  }

  handleVolume(e, val) {
    this.wavesurfer.setVolume(val/100);
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return String(minutes).padStart(1, "0") + ":" +  String(seconds).padStart(2, "0");

  }

  render() {

    const {playing, cur, dur} = this.state;

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Fab
            style={{marginRight: "20px"}}
            color="primary"
            size="large"
            onClick={this.togglePlaying}
          >
            {playing ? <PauseIcon/> : <PlayIcon/> }
          </Fab>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" style={{width: 90, paddingBottom: 5, marginRight: "20px"}}>
            {dur === 0 ? 'Loading Audio...' : `${this.formatTime(cur)} / ${this.formatTime(dur)}`}
          </Typography>
        </Grid>
        <Grid item md={1} xs>
          <Grid container spacing={2} style={{marginRight: "20px"}}>
            <Grid item>
              <VolumeDown />
            </Grid>
            <Grid item xs>
              <Slider defaultValue={100} onChange={this.handleVolume} />
            </Grid>
            <Grid item>
              <VolumeUp />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md xs={12}
          style={{ height: 80 }}
          id="waveform"
        />
      </Grid>
    );
  }
}

export default AudioPlayer;