import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  EditingState,
  SortingState,
  SearchState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableInlineCellEditing,
  SearchPanel,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import ls from 'local-storage';
import XLSX from 'xlsx';

const styles = theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
});

const getRowId = row => row._id;


class SchoolList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      columns: [
        { name: 'name', title: 'Name' },
      ],
      sorting: [{ columnName: 'name', direction: 'asc' }],
      rows: [],
    };
    this.changeSorting = sorting => this.setState({ sorting });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (changed) {
        const prevRow = rows.find(r => changed[r._id]);
        if (prevRow) {
          const newRow = {
            ...prevRow,
            ...changed[prevRow._id]
          };
          fetch('https://admin-api.coloradoaso.org/school', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'authorization': ls('admin-jwt')
            },
            body: JSON.stringify({
              updates: [newRow]
            })
          })
            .then(res => res.json())
            .then(res => {
              if (!res.error) {
                this.setState({
                  rows: res.rows
                })
              } else {
                this.setState({
                  error: `Something seems to have gone wrong(${res.error}).`
                });
              }
            });
        }
      }
      if (added) {
        fetch('https://admin-api.coloradoaso.org/school', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            news: added
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.rows
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
      if (deleted) {
        fetch('https://admin-api.coloradoaso.org/school', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': ls('admin-jwt')
          },
          body: JSON.stringify({
            dels: deleted
          })
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              this.setState({
                rows: res.rows
              })
            } else {
              this.setState({
                error: `Something seems to have gone wrong(${res.error}).`
              });
            }
          });

      }
    };
    this.downloadXLSX = this.downloadXLSX.bind(this);
  }

  convertToArray(row) {
    return [
      row.name
    ];
  }

  downloadXLSX() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ['name'],
      ...this.state.rows.map(r => this.convertToArray(r))
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Directors");
    XLSX.writeFile(wb, 'schools.xlsx');
  }

  componentDidMount() {
    fetch("https://admin-api.coloradoaso.org/schools", {
      method: 'get',
      headers: {
        'authorization': ls('admin-jwt')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            rows: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  render() {
    const { rows, columns, sorting, error } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.error ? true : false}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{error} Let Patrick know before trying to modify any data.</span>}
        />

        <Typography align="center" variant="h4" component="h4">
          Schools
        </Typography>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={this.commitChanges}
            defaultEditingRowIds={[0]}
          />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={10}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <SearchState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          
          <Table />
          
          <TableHeaderRow showSortingControls/>
          <Toolbar />
          <SearchPanel />
          <TableInlineCellEditing
          />
          <TableEditRow />
          <TableEditColumn
            showAddCommand
            showDeleteCommand
          />
          <PagingPanel 
            pageSizes={[5, 10, 50, 100, 0]}
          />
          
          
        </Grid>
        <Fab onClick={this.downloadXLSX} color="secondary" aria-label="Edit" className={classes.fab}>
          <SaveAltIcon />
        </Fab>
      </React.Fragment>
    );
  }
}

SchoolList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SchoolList);