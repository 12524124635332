import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link, Redirect } from "react-router-dom";
import ls from 'local-storage';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const error = err => {
  if(!err) return null;
  return <Typography color="error">{err}</Typography>;
}

class ForceChange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cpassword: "",
      password: "",
      error: null,
      redirectToReferrer: false
    };
    this.changePassword = this.changePassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleCPasswordChange = this.handleCPasswordChange.bind(this);
    this.logout = this.logout.bind(this);
  }
  changePassword(e) {
    e.preventDefault();
    if(this.state.password.length < 6) return this.setState({
      error: "Passwords must be at least 6 characters long."
    });
    if(this.state.password !== this.state.cpassword) return this.setState({
      error: "Ensure both passwords are the same."
    });
    const creds = {
      password: this.state.password
    } = this.state;
    fetch('https://api.coloradoaso.org/director-update-password', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'director': ls('director-jwt')
      },
      body: JSON.stringify(creds)
    })
    .then(res => res.json())
    .then(res => {
      if(res.error) {
        return this.setState({
          error: res.error
        })
      }
      ls('director-jwt', res.token);
      this.setState({
        redirectToReferrer: true
      });
    });

  }
  handleCPasswordChange(e) {
    this.setState({ cpassword: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  logout() {
    ls.remove('director-jwt');
    this.setState({
      redirectToReferrer: true
    });
  }

  render() {
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to='/director' />
    }

    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update Password
          </Typography>
          {error(this.state.error)}
          <form onSubmit={this.changePassword} className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={this.state.password} 
              onChange={this.handlePasswordChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="cpassword"
              label="Confirm Password"
              type="password"
              id="cpassword"
              autoComplete="confirm-password"
              value={this.state.cpassword} 
              onChange={this.handleCPasswordChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Change Password
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="#" onClick={this.logout} variant="body2">
                  Logout
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(ForceChange);