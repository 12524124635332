
import React from 'react';
import Slider from '@material-ui/core/Slider';

class PSlider extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.value !== nextProps.value;
  }

  render() {
    //console.log("render");
    return <Slider {...this.props} />;
  }

}

export default PSlider;