import React from 'react';
import PropTypes from 'prop-types';
/*
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
*/
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import AdminHeader from './adminheader';
import ls from "local-storage";
import jwtDecode from "jwt-decode";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  footer: {
    marginTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(6)}px 0`,
  },
});

class EmailLists extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: jwtDecode(ls('admin-jwt')),
      lists: {}
    };
  }

  componentDidMount() {
    fetch("https://admin-api.coloradoaso.org/emails", {
      method: 'get',
      headers: {
        'authorization': ls('admin-jwt')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            lists: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AdminHeader />
        <main className={classes.layout}>
          <Typography align="center" variant="h4" component="h4">
            Email Lists
          </Typography>
          <Typography align="center" paragraph>
            Select the entire line (usually by triple clicking on it) to copy paste into the BCC field.
          </Typography>
          <Grid container spacing={4}>
            {Object.keys(this.state.lists).map(listName => (
              <Grid item xs={12} key={listName}>
                <TextField
                  fullWidth
                  label={listName}
                  value={this.state.lists[listName].join(", ")}
                />
              </Grid>
            ))}
          </Grid>
          
          
        </main>
      </React.Fragment>
    );
  }

}

EmailLists.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmailLists);