import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';


import ls from 'local-storage';

import AdminHeader from './adminheader';
import AuditionListComponent from './auditionListComponent';

import instruments from '../../data/instruments';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    width: 'auto'
  },
});

class AdminAuditions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      confirm: null
    }
    this.generateInstrument = this.generateInstrument.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm(val) {
    this.setState({
      confirm: val
    });
  }

  generateInstrument(del) {
    const instrument = this.state.confirm;
    console.log(instrument, del);
    fetch('https://admin-api.coloradoaso.org/generate-codes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'authorization': ls('admin-jwt')
      },
      body: JSON.stringify({
        instrument: instrument.value,
        code: instrument.prefix,
        del: del
      })
    })
    .then(res => res.json())
    .then(res => {
      console.log(res);
      this.handleConfirm(null);
    });
  }

  render() {
    const { classes } = this.props;
    const { confirm } = this.state;
    return (
      <React.Fragment>
        <AdminHeader />
        <Grid container justify="center">
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Grid container spacing={1}>
                {instruments.filter(i => !i.placeholder).map(i => (
                  <Grid item key={i.value}>
                    <Button disabled={i.completed} variant="contained" onClick={() => this.handleConfirm(i)}>{i.value}</Button>
                  </Grid>
                ))}
              </Grid>
              
            </Paper>
            <Dialog
              open={confirm ? true : false}
              onClose={() => this.handleConfirm(null)}
            >
              {confirm ? <React.Fragment>
                <DialogTitle>{`Generate audition codes for ${confirm.value}?`}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    This will generate audition codes inside the master audition list. This action is not easily reversible.
                    Selecting 'Delete and Generate' <b>will</b> delete any scores created for that instrument.
                    Selecting 'Generate' may add duplicates if they have already been generated.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={() => this.generateInstrument(true)} color="secondary">
                    Delete and Generate
                  </Button>
                  <Button variant="contained" onClick={() => this.generateInstrument(false)} color="primary">
                    Generate
                  </Button>
                  <Button variant="contained" onClick={() => this.handleConfirm(null)} autoFocus>
                    Cancel
                  </Button>
                </DialogActions>
              </React.Fragment> : <div/>}  
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <AuditionListComponent/>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

}

AdminAuditions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminAuditions);