import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import ls from 'local-storage';
import jwt from 'jwt-decode';



import GraderSignIn from './login';
import Dashboard from './dashboard';
import Deadline from './pastDeadline';
import Rubric from './rubric';
import Review from './review';


const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = ls('grader-jwt');
  let user = null;
  try {
    user = jwt(token);
  } catch (e) {

  }
  

  return (
    <Route {...rest} render={(props) => (
        ls('grader-jwt') ? 
          <Component {...props} user={user} /> : <Redirect to={{ pathname: '/grader/login', state: { from: props.location }}} />   
    )} />
  );
};

class GraderRoutes extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    }
    this.timer = null;
    this.refreshToken = this.refreshToken.bind(this);

  }


  

  componentDidMount() {
    this.timer = setInterval(() => this.refreshToken(), 1000*60*60);
    
    this.refreshToken();
  }

  refreshToken() {
    console.log("refreshing token");
    if(ls('grader-jwt')) {
        fetch('https://api.coloradoaso.org/grader-refresh-token', {
        method: 'get',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'grader': ls('grader-jwt')
        },
      })
      .then(res => res.json())
      .then(res => {
        if(res.error) {
          return console.log('refresh error', res);
        }
        ls('grader-jwt', res.token);
        this.setState({
          user: jwt(res.token)
        });
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    let user = null;
    try {
      user = jwt(ls('grader-jwt'))
    }catch(e) {}

    if(user && new Date() > new Date(user.deadline) && this.props.location.pathname !== '/grader/deadline') {
      return  <Redirect to={{ pathname: '/grader/deadline', state: { from: this.props.location }}} />;
    }
      
    
    return (
      <Switch>
        <Route from="/grader/login" component={GraderSignIn} />
        <ProtectedRoute from="/grader/deadline" component={Deadline} />
        <ProtectedRoute from="/grader/audition/:code" component={Rubric} />
        <ProtectedRoute from="/grader/review" component={Review} />
        <ProtectedRoute from="/grader" component={Dashboard} />
      </Switch>
    );
  }

}

export default GraderRoutes;