import React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from '../../theme';

class Verify extends React.Component {

  constructor(props) {
    super(props);
    const { otp } = queryString.parse(this.props.location.search);
    this.state = {
      otp: otp || "",
      otpError: false,
      loading: false,
      redirect: ""
    }
    this.otpInput = this.otpInput.bind(this);
    this.submit = this.submit.bind(this);
  }

  otpInput(event) {
    this.setState({
      otp: event.target.value,
      otpError: false
    });
  }

  submit() {
    this.setState({
      loading: true
    });
    this.postData("https://api.coloradoaso.org/student-otp", {
      otp: this.state.otp,
      _id: this.props.match.params.id
    }).then(res => {
      if (res.errors) {
        this.setState({
          otpError: true,
          loading: false
        })
      } else {
        this.setState({
          redirect: this.props.match.params.id
        });
      }
    }).catch(err => {
      this.setState({
        otpError: true,
        loading: false
      });
    });
  }

  postData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => response.json()); // parses response to JSON
  }

  render() {
    const { classes } = this.props;

    if (this.state.redirect.length > 0) {
      return <Redirect to={`/student-registration/review/${this.state.redirect}`} />
    }

    
    if(this.state.email_verified) return <div>Already Verified.</div>;

    return (
      <React.Fragment>
        <Typography variant="h6" align="center" gutterBottom>
          Verify Email
        </Typography>
        <Grid container justify="center">
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={this.state.otp}
              onChange={this.otpInput}
              error={this.state.otpError}
              id="otp"
              name="otp"
              label="Code"
              InputLabelProps={{
                className: classes.verifyFieldHint
              }}
              fullWidth
              variant="outlined"
              inputProps={{
                className: classes.verifyField,
              }}
              disabled={this.state.loading}
            />
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              className={classes.verifyButton}
              onClick={this.submit}
              disabled={this.state.loading}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>

    );
  }
};

export default withStyles(styles)(Verify);