import React from 'react';
import update from 'react-addons-update';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import GraderLayout from './components/GraderLayout';
import Rubrics from './../../data/rubrics';
import Scales from './../../data/scales';
import { Redirect } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';

import Slider from './components/Slider';
import AudioPlayer from './components/AudioPlayer';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

const getScore = (scores, part, section, score) => {
  if (Object.keys(scores).length === 0) return 0;
  return scores[part][section][score];
}

const ValueLabelComponent = format => props => {
  const { children, open, value } = props;
  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={`${value} - ${format(value)}`}
    >
      {children}
    </Tooltip>
  );
}

class Rubric extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      audition: null,
      scores: {},
      comment: "",
      submitting: false,
      redirect: ""
    }
    this.changeScore = this.changeScore.bind(this);
    this.checkScores = this.checkScores.bind(this);
    this.handlers = {};
  }

  checkScores() {

    const flattenObj = (ob) => {

      // The object which contains the
      // final result
      let result = {};

      // loop through the object "ob"
      for (const i in ob) {

        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
          const temp = flattenObj(ob[i]);
          for (const j in temp) {

            // Store temp in result
            result[i + '.' + j] = temp[j];
          }
        }

        // Else store ob[i] in result directly
        else {
          result[i] = ob[i];
        }
      }
      return result;
    };
    const { scores, comment } = this.state;
    const unscoredItems = Object.entries(flattenObj(scores)).filter( ([key, value]) => {
      return value === -1;
    } ).map(([key, _]) => key );
    
    if(unscoredItems.length > 0) {
      alert(`There are some unscored items:\n${unscoredItems.join('\n')}\n`);
      return;
    }
    let data = { ...scores, comment };
    this.setState({
      submitting: true
    });
    fetch("https://api.coloradoaso.org/grader-score", {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        'grader': ls('grader-jwt')
      },
      body: JSON.stringify({ code: this.props.match.params.code, scores: data })
    })
      .then(res => res.json())
      .then(
        (result) => {

          console.log(result)

          this.setState({
            submitting: false,
            redirect: this.getNextAuditionCode()
          });
        },
        (error) => {
          this.setState({
            submitting: false,
            error: error.message
          });
        }
      )
  }


  getNextAuditionCode() {
    try {
      const auds = this.auditions[this.state.audition.instrument];
      for (const audInd in auds) {
        if (auds[audInd].code === this.props.match.params.code) {
          if (audInd < auds.length - 1) {
            return `/grader/audition/${auds[Number(audInd) + 1].code}`;
          }
          else return "/grader"
        }
      }
    } catch (e) {
      console.log(e)
      return "";
    }
    return "";
  }

  generateScores(audition) {
    console.log("Generating scores...");

    const { user } = this.props;
    const rubric = (!audition || !Rubrics[audition.instrument]) ? null : Rubrics[audition.group || audition.instrument];
    let scores = {};

    scores.comment = "";

    for (const part of rubric.parts) {
      scores[part.name] = {};
      this.handlers[part.name] = {};
      scores[`${part.name} Total`] = 0;
      for (const section of part.sections) {
        scores[part.name][section.name] = {};
        this.handlers[part.name][section.name] = {};
        scores[part.name][`${section.name} Total`] = 0;
        for (const score of section.scores) {
          scores[part.name][section.name][score.name] = -1;
          this.handlers[part.name][section.name][score.name] = this.changeScore(part.name, section.name, score.name);
          this.handlers[part.name][section.name][score.name] = this.handlers[part.name][section.name][score.name].bind(this);
        }
      }
    }

    scores['Total'] = 0;


    if (audition.grades[user.name]) scores = audition.grades[user.name];
    this.setState({ scores, comment: scores.comment });

  }

  zeroOut(val) {
    return val === -1 ? 0 : val;
  }

  getTotal(scores) {
    const { audition } = this.state;
    const rubric = (!audition || !Rubrics[audition.instrument]) ? null : Rubrics[audition.group || audition.instrument];

    //console.log(scores, audition, rubric);

    let total = 0;
    for (const part of rubric.parts) {
      for (const section of part.sections) {
        for (const score of section.scores) {
          total += this.zeroOut(scores[part.name][section.name][score.name]);
        }
      }
    }
    return total;
  }

  getPartTotal(scores, partName) {
    const { audition } = this.state;
    const rubric = (!audition || !Rubrics[audition.instrument]) ? null : Rubrics[audition.group || audition.instrument];

    //console.log(scores, audition, rubric);

    let total = 0;
    for (const part of rubric.parts) {
      if (part.name === partName) {
        for (const section of part.sections) {
          for (const score of section.scores) {
            total += this.zeroOut(scores[part.name][section.name][score.name]);
          }
        }
      }
    }


    return total;
  }

  getSectionTotal(scores, partName, sectionName) {
    const { audition } = this.state;
    const rubric = (!audition || !Rubrics[audition.instrument]) ? null : Rubrics[audition.group || audition.instrument];

    //console.log(scores, audition, rubric);

    let total = 0;
    for (const part of rubric.parts) {
      if (part.name === partName) {
        for (const section of part.sections) {
          if (section.name === sectionName) {
            for (const score of section.scores) {
              total += this.zeroOut(scores[partName][sectionName][score.name]);
            }
          }
        }
      }
    }

    return total;
  }

  changeScore(part, section, score) {
    return (event, newValue) => {
      if (newValue !== this.state.scores[part][section][score]) {
        const newScores = update(this.state.scores, {
          [part]: {
            [section]: {
              [score]: {
                '$set': newValue
              }
            }
          },
        });
        newScores[`Total`] = this.getTotal(newScores);
        newScores[`${part} Total`] = this.getPartTotal(newScores, part);
        newScores[part][`${section} Total`] = this.getSectionTotal(newScores, part, section);
        this.setState({
          scores: newScores
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.code !== prevProps.match.params.code) {
      console.log("we did update to ", this.props.match.params.code);
      this.setState({
        loading: true,
        audition: null,
        scores: {},
        comment: "",
        submitting: false,
        redirect: ""
      });
      this.handlers = {};
      this.loadAudition();
    }
  }

  loadAudition() {
    fetch("https://api.coloradoaso.org/grader-audition", {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        'grader': ls('grader-jwt')
      },
      body: JSON.stringify({ code: this.props.match.params.code })
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.generateScores(result);
          this.setState({
            loading: false,
            audition: result
          });

        },
        (error) => {
          this.setState({
            loading: false,
            error: error.message
          });
        }
      )
  }

  componentDidMount() {
    this.loadAudition();
  }

  setAuditions(auditions) {
    this.auditions = auditions;
  }

  render() {

    if (this.state.redirect) return <Redirect push to={this.state.redirect} />

    const { audition, scores, comment } = this.state;
    const { user } = this.props;

    const rubric = (!audition || !Rubrics[audition.instrument]) ? null : Rubrics[audition.group || audition.instrument];

    return (
      <GraderLayout setAllAuditions={(auditions) => this.setAuditions(auditions)} user={user}>
        {rubric && <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3">
                Audition {audition.code}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {audition && <AudioPlayer audition={audition} />}
            </Grid>
            {rubric.parts.map(part => (
              <React.Fragment key={part.name}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {part.name} - {scores[`${part.name} Total`]} Points
                  </Typography>
                  <Typography paragraph>
                    {part.description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {part.sections.map(section => (
                    <Grid container key={section.name}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {section.name} - {scores[part.name][`${section.name} Total`]} Points
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {section.scores.map(score => (
                          <Grid container spacing={1} key={score.name}>
                            <Grid item xs={12}>
                              <Typography variant="h6" gutterBottom>
                                {score.name === "SOLO" ? `${audition.solo.name} - ${audition.solo.composer}` : score.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {score.name === "SOLO" ? (
                                <TextField
                                  label="Solo Difficulty"
                                  type="number"
                                  value={this.zeroOut(getScore(scores, part.name, section.name, score.name))}
                                  onChange={(e) => this.handlers[part.name][section.name][score.name](e, Math.min(20, Math.max(0, Number(e.target.value))))}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  margin="normal"
                                />
                              ) : (
                                <Slider
                                  value={getScore(scores, part.name, section.name, score.name)}
                                  onChange={this.handlers[part.name][section.name][score.name]}
                                  valueLabelDisplay="auto"
                                  ValueLabelComponent={ValueLabelComponent(Scales[score.scale].tag)}
                                  step={Scales[score.scale].step}
                                  marks={Array.from({ length: Scales[score.scale].max + 1 }, (v, k) => ({ value: k, label: k }))}
                                  min={Scales[score.scale].min}
                                  max={Scales[score.scale].max}
                                />
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Divider light variant="inset" />
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                value={comment}
                onChange={(e) => this.setState({ comment: e.target.value })}
                margin="normal"
                label="Audition Comments"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">
                Audition Total: {scores[`Total`]} Points
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" disabled={this.state.submitting} onClick={this.checkScores}>
                {this.state.submitting ? <CircularProgress /> : "Submit Scores"}
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>}
      </GraderLayout>

    );
  }
}

Rubric.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Rubric);