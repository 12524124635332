import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({

});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class PhoneInput extends React.PureComponent {
  /* constructor(props) {
    super(props);
  } */

  render() {
    const {
    //classes,
      value,
      onChange,
      helperText,
      id,
      error,
      label,
      required
    } = this.props;
    return (
      <FormControl required={required} error={error}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Input
          value={value}
          onChange={onChange}
          id={id}
          inputComponent={TextMaskCustom}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }

}

PhoneInput.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool
};

export default withStyles(styles)(PhoneInput);