import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Fab from '@material-ui/core/Fab';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  DataTypeProvider,
  SortingState,
  EditingState,
  SearchState,
  GroupingState,
  IntegratedGrouping,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableGroupRow,
  SearchPanel,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import ls from 'local-storage';
import XLSX from 'xlsx';

const styles = theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
});

const BooleanFormatter = ({ value }) => <Chip label={value ? 'Yes' : 'No'} />;

const BooleanEditor = ({ value, onValueChange }) => (
  <Select
    input={<Input />}
    value={value ? 'Yes' : 'No'}
    onChange={event => onValueChange(event.target.value === 'Yes')}
    style={{ width: '100%' }}
  >
    <MenuItem value="Yes">
      Yes
    </MenuItem>
    <MenuItem value="No">
      No
    </MenuItem>
  </Select>
);

const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
);


const getRowId = row => row._id;

const compareScores = (aa, bb) => {
  const a = aa['Average'].total;
  const b = bb['Average'].total;
  return b-a;
}

function sort(arr) {
  return arr.sort((a, b) => {
    if(a === "Average" || a === "Summed") return 1;
    if(b === "Average" || b === "Summed") return -1;
    if (a > b) {
      return 1;
    }
    if (b > a) {
        return -1;
    }
    return 0;
  });
}

const GradeFormatter = ({value}) => (
  <div>
    {sort(Object.keys(value)).map((grader, i) => (
      <div key={grader} style={{width: "100%"}}>
        <div style={{float: "left"}}>{grader}: </div><div style={{float: 'right'}}>{value[grader].total}</div>
        {i === Object.keys(value).length -3 ? <hr style={{clear:"both"}}/> : <div style={{clear:'both'}}/>}
      </div>
    ))}
  </div>  
);

const GradeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={GradeFormatter}
    editorComponent={GradeFormatter}
    {...props}
  />
);

class AuditionList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      columns: [
        { name: 'instrument', title: 'Instrument'},
        { name: 'code', title: 'Audition Code' },
        { name: 'studentName', title: 'Student Name', getCellValue: row => {console.log(row); return `${row.student.firstName} ${row.student.lastName}`}},
        { name: 'directorName', title: 'Director Name', getCellValue: row => `${row.student.director.firstName} ${row.student.director.lastName}`},
        { name: 'school', title: 'School', getCellValue: row => row.student.director.school},
        { name: 'grade', title: 'Scores'},
        { name: 'accepted', title: 'Accepted?' }
      ],
      sorting: [{ columnName: 'name', direction: 'asc' }],
      rows: [],
    };
    this.changeSorting = sorting => this.setState({ sorting });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (changed) {
        const prevRow = rows.find(r => changed[r._id]);
        if (prevRow) {
          const newRow = {
            ...prevRow,
            ...changed[prevRow._id]
          };
          fetch('https://admin-api.coloradoaso.org/audition', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'authorization': ls('admin-jwt')
            },
            body: JSON.stringify({
              updates: [newRow]
            })
          })
            .then(res => res.json())
            .then(res => {
              if (!res.error) {
                this.setAuditions(res.auditions);
                this.setState({
                  isLoaded: true,
                  rows: res.auditions
                });
              } else {
                this.setState({
                  error: `Something seems to have gone wrong(${res.error}).`
                });
              }
            });
        }
      }
    };
    this.downloadXLSX = this.downloadXLSX.bind(this);
  }

  convertToArray(row) {
    return [
      `${row.student.firstName} ${row.student.lastName}`,
      `${row.student.email}`,
      `${row.student.director.school}`,
      `${row.student.director.firstName} ${row.student.director.lastName}`,
      `${row.student.director.email}`,
      `${row.instrument}`,
      `${row.grade.Summed.total}`,
      `${row.accepted ? 'Yes' : 'No'}`
    ];
  }

  downloadXLSX() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [
        'Student Name', 
        'Student Email',
        'School', 
        'Director Name',
        'Director Email',
        'Instrument', 
        'Summed Total Score',
        'Accepted'
      ],
      ...this.state.rows.map(r => this.convertToArray(r))
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "2020 Auditions");
    XLSX.writeFile(wb, 'auditions.xlsx');
  }

  setAuditions(auditions) {
    const seenGraders = [];
    for(const audition of auditions) {
      const gradeObj = {};
      let average = 0;
      let averageCount = 0;
      for(const grader of Object.keys(audition.grades)) {
        average += audition.grades[grader].Total;
        averageCount ++;
        if(seenGraders.indexOf(grader) === -1) seenGraders.push(grader);
        gradeObj[grader] = {
          total: audition.grades[grader].Total
        }
      }
      gradeObj.Average = {};
      gradeObj.Average.total = averageCount === 0 ? "Not Graded" : Math.round(100*average/averageCount)/100;
      gradeObj.Summed = {};
      gradeObj.Summed.total = average;
      audition.grade = gradeObj;
    }
  }

  componentDidMount() {
    fetch("https://admin-api.coloradoaso.org/auditions", {
      method: 'get',
      headers: {
        'authorization': ls('admin-jwt')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          for(const row of result) {
            if(!row) {
              console.log("null row?", row);
              if(!row.student) console.log(row);
              if(!row.student.director) console.log(row);
            }
          }
          this.setAuditions(result);
          this.setState({
            isLoaded: true,
            rows: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  render() {
    const { classes } = this.props;
    const { rows, columns, sorting, error } = this.state;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.error ? true : false}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{error} Let Patrick know before trying to modify any data.</span>}
        />

        <Typography align="center" variant="h4" component="h4">
          Auditions (WIP)
        </Typography>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <GradeTypeProvider for={['grade']} />
          <BooleanTypeProvider
            for={['accepted']}
          />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={0}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <EditingState
            onCommitChanges={this.commitChanges}
            defaultEditingRowIds={[0]}
            columnExtensions={[
              {columnName: 'instrument' , editingEnabled: false},
              {columnName: 'code' , editingEnabled: false},
              {columnName: 'studentName' , editingEnabled: false},
              {columnName: 'directorName' , editingEnabled: false},
              {columnName: 'school' , editingEnabled: false},
              {columnName: 'grade' , editingEnabled: false}
            ]}
          />
          <SearchState />
          <GroupingState
            grouping={[{ columnName: 'instrument' }]}
          />

          <IntegratedFiltering />
          <IntegratedSorting 
            columnExtensions={[
              { columnName: 'grade', compare: compareScores }
            ]}
          />
          <IntegratedPaging />
          <IntegratedGrouping />

          <Table />
          
          <TableHeaderRow showSortingControls/>
          <Toolbar />
          <SearchPanel />
          <TableGroupRow />
          <TableEditRow />
          <TableEditColumn
            showEditCommand
          />
          <PagingPanel 
            pageSizes={[5, 10, 50, 100, 0]}
          />
          
          
        </Grid>
        <Fab onClick={this.downloadXLSX} color="secondary" aria-label="Edit" className={classes.fab}>
          <SaveAltIcon />
        </Fab>
      </React.Fragment>
    );
  }
}

AuditionList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AuditionList);