import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import ls from 'local-storage';
import jwt from 'jwt-decode';


import DirectorSignIn from './login';
import Dashboard from './dashboard';
import ForceChange from './forceChange';
import PasswordReset from './reset';
import Deadline from './pastDeadline';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = ls('director-jwt');
  let user = null;
  try {
    user = jwt(token);
  } catch (e) {

  }
  

  return (
    <Route {...rest} render={(props) => (
        ls('director-jwt') ? 
          <Component {...props} user={user} /> : <Redirect to={{ pathname: '/director/login', state: { from: props.location }}} />   
    )} />
  );
};

class AdminRoutes extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
    this.timer = null;
    this.refreshToken = this.refreshToken.bind(this);
  }

  

  

  componentDidMount() {
    this.timer = setInterval(() => this.refreshToken(), 10000)
    
    this.refreshToken();
  }

  refreshToken() {
    console.log("refreshing token");
    if(ls('director-jwt')) {
        fetch('https://api.coloradoaso.org/director-refresh-token', {
        method: 'get',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'director': ls('director-jwt')
        },
      })
      .then(res => res.json())
      .then(res => {
        if(res.error) {
          if(res.error ==="not logged in") {
            console.log('not logged in!')
            ls.remove('director-jwt');
            this.setState({
              user: null
            });
          }
          return console.log('refresh error', res);
        }
        ls('director-jwt', res.token);
        this.setState({
          user: jwt(res.token)
        });
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    console.log("render!", this.props.location.pathname);
    let user = null;
    try {
      user = jwt(ls('director-jwt'))
    }catch(e) {}

    if(user && new Date() > new Date(user.pwreset) && this.props.location.pathname !== '/director/password-update') {
      return  <Redirect to={{ pathname: '/director/password-update', state: { from: this.props.location }}} />;
    }

    if(user && new Date() > new Date(user.deadline) && this.props.location.pathname !== '/director/deadline') {
      return  <Redirect to={{ pathname: '/director/deadline', state: { from: this.props.location }}} />;
    }
      

    return (
      <React.Fragment>
        <Switch>
          <Route from="/director/login" component={DirectorSignIn} />
          <Route from="/director/password-reset" component={PasswordReset} />
          <ProtectedRoute from="/director/password-update" component={ForceChange} />
          <ProtectedRoute from="/director/deadline" component={Deadline} />
          <ProtectedRoute from="/director" component={Dashboard} />
        </Switch>
      </React.Fragment>
    );
  }

}

export default AdminRoutes;