import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import Form from '../../layouts/form';

import DirectorInfo from './DirectorInfo';
import Verify from './verify';
import Review from './review';
import Complete from './complete';

const steps = ['Director Information', 'School Information', 'Password'];

class DirectorRegistration extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Switch>

          <Redirect exact from="/director-registration" to="/director-registration/information" />
          <Redirect exact from="/director-registration/verify" to="/director-registration/information" />
          <Redirect exact from="/director-registration/review" to="/director-registration/information" />
          <Redirect exact from="/director-registration/success" to="/director-registration/information" />

          <Route from="/director-registration/verify/:id" render={props => <Form title="Director Registration" CurrentStep={Verify} steps={steps} step_index={1} {...props} />} />
          <Route from="/director-registration/review/:id" render={props => <Form title="Director Registration" CurrentStep={Review} steps={steps} step_index={2} {...props} />} />
          <Route from="/director-registration/success/:id" render={props => <Form title="Director Registration" CurrentStep={Complete} steps={steps} step_index={3} {...props} />} />

          <Route render={props => <Form title="Director Registration" CurrentStep={DirectorInfo} steps={steps} step_index={0} {...props} />} />
          
        </Switch>
      </React.Fragment>
    );
  }

}

export default DirectorRegistration;