import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';

import Home from './routes/root';
import DirectorRegistration from './routes/director_registration';
import StudentRegistration from './routes/student_registration';
import Admin from './routes/admin';
import Director from './routes/director';
import Grader from './routes/grading';
import Page404 from './routes/root/404';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/director-registration" component={DirectorRegistration} />
          <Route path="/student-registration" component={StudentRegistration} />
          <Route path="/caso-admin" component={Admin} />
          <Route path="/director" component={Director} />
          <Route path="/grader" component={Grader} />
          <Route component={Page404} />
        </Switch>
      </Router>
    );
  }
}

export default App;
