import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import { Link, Redirect } from "react-router-dom";
import ls from 'local-storage';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const error = err => {
  if(!err) return null;
  return <Typography color="error">{err}</Typography>;
}

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: "",
      error: null,
      codeInput: false,
      code: "",
      redirectToReferrer: false
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.submitCode = this.submitCode.bind(this);
    this.switchToCode = this.switchToCode.bind(this);
  }

  switchToCode() {
    this.setState({
      loading: false,
      codeInput: true
    });
  }

  resetPassword(e) {
    e.preventDefault();
    this.setState({
      loading: true
    });
    fetch('https://api.coloradoaso.org/director-reset-start', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.username
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.error) {
        return this.setState({
          error: res.error,
          loading: false,
        });
      }
      this.setState({
        error: "",
        codeInput: true,
        loading: false
      });
    }).catch(err => {
      this.setState({
        loading: false,
        error: "An error occured. Please try again later."
      });
    });
  }

  submitCode(e) {
    e.preventDefault();
    this.setState({
      loading: true
    });
    fetch('https://api.coloradoaso.org/director-reset-end', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.username,
        code: this.state.code
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.error) {
        return this.setState({
          error: res.error,
          loading: false,
        });
      }
      ls('director-jwt', res.token);
      this.setState({
        redirectToReferrer: true
      });
    }).catch(err => {
      this.setState({
        loading: false,
        error: "An error occured. Please try again later."
      });
    });
  }

  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  handleCodeChange(e) {
    this.setState({ code: e.target.value });
  }


  render() {
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to='/director' />
    }

    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Collapse in={!this.state.codeInput}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography> 
            {error(this.state.error)}
            <form onSubmit={this.resetPassword} className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                disabled={this.state.loading}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={this.state.username} 
                onChange={this.handleUsernameChange}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                className={classes.submit}
              >
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/director/login" variant="body2">
                    Login
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Collapse>
        <Collapse in={this.state.codeInput}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Code
            </Typography> 
            {error(this.state.error)}
            <form onSubmit={this.submitCode} className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Reset Code"
                name="email"
                autoComplete="email"
                value={this.state.code} 
                onChange={this.handleCodeChange}
                autoFocus
                disabled={this.state.loading}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={this.state.loading}
              >
                Submit Code
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/director/login" variant="body2">
                    Login
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Collapse>
      </Container>
    );
  }
}

export default withStyles(styles)(ResetPassword);