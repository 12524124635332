import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MUIGrid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import {
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import ls from 'local-storage';

import UploadComponent from './uploadComponent';
import AudioPlayer from './audioPlayerSmallComponent';

const styles = theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  }
});

function postData(url, data) {
  // Default options are marked with *
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      'director': ls('director-jwt')
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => response.json()); // parses response to JSON
}

function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

const getRowId = row => row._id;

const buildInstrumentList = (instruments, uploads) => {
  const comps = [];
  let id = 0;
  for(const instrument of instruments) {
    comps.push(<span key={instrument} style={{color: uploads[instrument] ? "#01750d" : "#8c0000"}}>{uploads[instrument]? "✔" : "✘"}{instrument}</span>);
    comps.push(<span key={id++}>, </span>);
  }
  comps.pop();
  return comps;
}

const InstrumentsFormatter = ({ value }) => {
  return (
    <div>
      {buildInstrumentList(value.instruments, value.uploads || {})}
    </div>
  );
}

const InstrumentsTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={InstrumentsFormatter}
    {...props}
  />
);

const DeleteFormatter = ({value}) => {
  return (
    <IconButton onClick={(e)=>{e.stopPropagation();value.setDeletion(value.student)}} aria-label="delete">
      <DeleteIcon/>
    </IconButton>
  );
}

const DeleteTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={DeleteFormatter}
    {...props}
  />
);  

class StudentList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.changeSorting = sorting => this.setState({ sorting });
    this.uploadClose = this.uploadClose.bind(this);
    this.setUpload = this.setUpload.bind(this);
    this.TableRow = this.TableRow.bind(this);
    this.refreshStudents = this.refreshStudents.bind(this);
    this.RowDetail = this.RowDetail.bind(this);
    this.deleteStudent = this.deleteStudent.bind(this);
    this.setDeletion = this.setDeletion.bind(this);

    this.state = {
      isLoading: true,
      selectedUpload: null,
      deleteDialog: null,
      error: null,
      playerURL: 'asdf',
      columns: [
        { 
          name: 'firstName', 
          title: 'First' 
        },
        { 
          name: 'lastName', 
          title: 'Last' 
        },
        { 
          name: 'email', 
          title: 'Email' 
        },
        {
          name: 'instruments',
          title: 'Instruments',
          getCellValue: row => ({
            instruments: row.instruments,
            uploads: row.uploads || {},
            solos: row.solos
          })
        },
        {
          name: 'delete',
          title: 'Remove',
          getCellValue: row => {
            return {
              student: row,
              setDeletion: this.setDeletion
            };
          }
        }
        

      ],
      sorting: [{ columnName: 'lastName', direction: 'asc' }],
      rows: [],
    };
      
    
  }

  setDeletion(student) {
    this.setState({
      deleteDialog: student
    });
  }

  

  RowDetail ({ row }) {
    const {
      _id,
      firstName,
      lastName,
      address,
      email,
      cell,
      home,
      emergency,
      instruments,
      uploads
    } = row;
    //const { changeAudioUrl } = this.props;
    return (
      <MUIGrid container spacing={1}>
        <MUIGrid item xs={6} md={4}>
          {address.address1}<br />
          {address.address2.length > 0 ? (<span>{address.address2}<br /></span>) : null}
          {address.city}, {address.state} {address.zip}<br />
          {email}<br />
          Cell: {formatPhoneNumber(cell)}<br />
          {home.length > 0 ? (<span>Home: {formatPhoneNumber(home)}<br /></span>) : null}
        </MUIGrid>
        <MUIGrid item xs={6} md={4}>
          <b>Emergency Contact</b><br />
          {emergency.name}<br />
          {formatPhoneNumber(emergency.number)}<br />
          {emergency.relationship}
        </MUIGrid>
        <MUIGrid item xs={6} md={4}>
          <MUIGrid container>
            {
              instruments.map(instrument => uploads[instrument] && (
                <MUIGrid key={instrument} item xs={12}>
                  <MUIGrid spacing={1} justify="flex-start" alignItems="center" direction="row" container>
                    <MUIGrid item>
                      <AudioPlayer info={{
                        firstName,
                        lastName,
                        _id,
                        instrument
                      }} />
                    </MUIGrid>
                    <MUIGrid item>
                      {instrument}
                    </MUIGrid>
                  </MUIGrid>
                </MUIGrid>
              ))
            }
          </MUIGrid>
        </MUIGrid>
      </MUIGrid>
    )
  }

  TableRow ({ row, ...restProps }) {
    return (
      <Table.Row
        {...restProps}
        onClick={() => this.setUpload(row)}
        style={{
          cursor: 'pointer'
        }}
      />
    );
  }

  uploadClose() {
    this.setState({
      selectedUpload: null
    });
  }

  setUpload(id) {
    this.setState({
      selectedUpload: id
    });
  }

  deleteStudent() {
    postData("https://api.coloradoaso.org/student-delete", {id: this.state.deleteDialog._id}).then(res => {
      this.setState({
        deleteDialog: null
      });  
      this.refreshStudents();
    });
  }

  convertToArray(row) {
    return [
      row.firstName,
      row.lastName,
      row.email,
      row.cell,
      row.home
    ];
  }

  refreshStudents() {
    this.setState({
      selectedUpload: null,
      rows: [],
      isLoading: true
    });
    this.loadStudents();
  }

  loadStudents() {
    fetch("https://api.coloradoaso.org/director-students", {
      method: 'get',
      headers: {
        'director': ls('director-jwt')
      }
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          rows: result
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error: error.message
        });
      }
    )
  }

  componentDidMount() {
    this.loadStudents();
  }

  render() {
    const { 
      rows, 
      columns, 
      sorting,  
      error,
      selectedUpload,
      deleteDialog
    } = this.state;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.error ? true : false}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{error} Email ColoradoASO@gmail.com to report this error.</span>}
        />

        <UploadComponent doClose={this.uploadClose} refresh={this.refreshStudents} student={selectedUpload} />
        
        <Dialog
          open={deleteDialog !== null}
          onClose={() => this.setDeletion(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {deleteDialog !== null && (
            <React.Fragment>
              <DialogTitle id="alert-dialog-title">{`Remove student ${deleteDialog.firstName} ${deleteDialog.lastName}?`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Removing a student will remove them from the audition list. If the student would like to audition they can re-register after deletion.
                  This will also remove any uploaded audition files for the student.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setDeletion(null)} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.deleteStudent} color="primary" autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
          
        </Dialog>

        <Typography align="center" variant="h5" component="h5">
          Students
        </Typography>
        <Paper elevation={5}>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
          >
            <InstrumentsTypeProvider for={["instruments"]} />
            <DeleteTypeProvider for={["delete"]} />
            <RowDetailState
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <IntegratedSorting />
            <Table columnExtensions={
              [
                {columnName:'instruments', wordWrapEnabled: true},
                {columnName: 'delete', width: 100},
              ]
            } rowComponent={this.TableRow} />
            
            <TableHeaderRow showSortingControls/>
            <TableRowDetail
              contentComponent={this.RowDetail}
            />
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

StudentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentList);