import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import ls from 'local-storage';
import AdminDashboard from './dashboard';
import AdminDirectors from './admindirectors';
import AdminStudents from './adminstudents';
import AdminSchools from './adminschools';
import AdminAuditions from './adminauditions';
import AdminAdjudicators from './adminadjudicators';
import AdminSignIn from './adminlogin';
import AdminEmails from './emailLists';
import AdminPayments from './adminpayments';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
      ls('admin-jwt') ? 
        <Component {...props} /> : <Redirect to={{ pathname: '/caso-admin/login', state: { from: props.location }}} />   
  )} />
);

class AdminRoutes extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route from="/caso-admin/login" component={AdminSignIn} />
          <ProtectedRoute from="/caso-admin/directors" component={AdminDirectors} />
          <ProtectedRoute from="/caso-admin/students" component={AdminStudents} />
          <ProtectedRoute from="/caso-admin/schools" component={AdminSchools} />
          <ProtectedRoute from="/caso-admin/auditions" component={AdminAuditions} />
          <ProtectedRoute from="/caso-admin/emails" component={AdminEmails} />
          <ProtectedRoute from="/caso-admin/adjudicators" component={AdminAdjudicators} />
          <ProtectedRoute from="/caso-admin/payments" component={AdminPayments} />
          <ProtectedRoute from="/caso-admin" component={AdminDashboard} />
          
        </Switch>
      </React.Fragment>
    );
  }

}

export default AdminRoutes;